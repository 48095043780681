@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

.error_page {
    min-height: 98vh;
    padding-top: 100px;
    padding-left: $sideSpaceMd;
    padding-right: $sideSpaceMd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -54px;
    .home_btn {
        @extend %button;
        @extend %mainButton;
        @extend %largeButton;
        width: 244px;
        margin-top: 2*$sideSpaceMd;
    }
    .decor_block {
        width: 480px;
        max-width: 100%;
        position: relative;
        img {
            width: 167px;
            vertical-align: top;
            position: relative;
            z-index: 1;
        }
        &:before {
            @include psevdoAbs();
            left: 0;
            width: 100%;
            top: 54px;
            height: 70px;
            background: url('/resources/images/svg/shadow.svg') no-repeat;
            background-size: auto 100%;
            background-position: top center;
            opacity: 0.21;
        }
        &:after {
            @include psevdoAbs();
            left: 0;
            width: 100%;
            top: 63px;
            height: 98px;
            background: url('/resources/images/svg/shape.svg') no-repeat;
            background-size: auto 100%;
            background-position: top center;
            z-index: 2;
        }
    }
    .error_number {
        margin-top: $sideSpace;
        margin-bottom: 2*$sideSpaceMd;
        position: relative;
        z-index: 5;
        img {
            display: block;
            width: 338px;
            max-width: 100%;
        }
    }
    .page_description {
        margin-top: $sideSpaceLg;
        @include color($fieldColor,--fieldColor);
        font-size: 180%;
        line-height: 24px;
    }
    @media screen and (max-height: 719px) {
        .error_number {
            margin-top: $sideSpaceMd;
            margin-bottom: $sideSpace;
            img {
                width: 300px;
            }
        }
        .home_btn {
            margin-top: $sideSpace;
        }
        .decor_block {
            img {
                width: 140px;
            }
            &:before {
                top: 44px;
            }
            &:after {
                top: 53px;
            }
        }
    }
    @media screen and (max-height: 599px) {
        .error_number {
            margin-top: $sideSpaceSm;
            margin-bottom: $sideSpaceMd;
            img {
                width: 240px;
            }
        }
        .home_btn {
            margin-top: $sideSpaceLg;
        }
        .decor_block {
            img {
                width: 100px;
            }
            &:before {
                top: 30px;
            }
            &:after {
                top: 40px;
            }
        }
        .page_description {
            font-size: 140%;
            line-height: 18px;
            margin-top: $sideSpaceSm;
        }
    }
    @media #{$until1199} {
        margin-top: -71px;
        margin-bottom: -66px;
        padding-top: 120px;
        padding-bottom: 80px;
    }
    @media #{$until767} {
        .page_description {
            font-size: 140%;
            line-height: 20px;
        }
    }
}


@import '../../default-scss/extendsEnd';
