@import './variables';

:root {
    --siteColor: #{$siteColor};
    --siteColorHover: #{$siteColorHover};
    --siteColor007: #{$siteColor007};
    --siteBg: #{$siteBg};
    --textColor: #{$textColor};
    --textColor05: #{$textColor05};
    --textColor07: #{$textColor07};
    --lightBg: #{$lightBg};
    --lightColor: #{$lightColor};
    --lightBgHover: #{$lightBgHover};
    --indicator: #{$indicator};
    --indicatorBorder: #{$indicatorBorder};
    --logoRound: #{$logoRound};
    --timeBg: #{$timeBg};
    --notBtnBg: #{$notBtnBg};
    --fieldColor: #{$fieldColor};
    --fieldPh: #{$fieldPh};
    --userBg: #{$userBg};
    --bodyBg: #{$bodyBg};
    --standardBorder: #{$standardBorder};
    --pending: #{$pending};
    --pending01: #{$pending01};
    --reject: #{$reject};
    --reject01: #{$reject01};
    --rejectBorder: #{$rejectBorder};
    --rejectBg: #{$rejectBg};
    --rejectFieldBg: #{$rejectFieldBg};
    --success: #{$success};
    --success01: #{$success01};
    --detailsBorder: #{$detailsBorder};
    --scrollTrack: #{$scrollTrack};
    --fieldBg: #{$fieldBg};
    --coverLayer: #{$coverLayer};
    --blurBg: #{$blurBg};
    --navMobileBg: #{$navMobileBg};
    --bottomMenuColor: #{$bottomMenuColor};
    --animateStart: #{$animateStart};
    --animateEnd: #{$animateEnd};
    --gamesNavBg: #{$gamesNavBg};
    --gamesNavBg00: #{$gamesNavBg00};
    --historyBg: #{$historyBg};
    --tableBorder: #{$tableBorder};
    --description: #{$description};
    --statusTrack: #{$statusTrack};
    --white: #{$white};
    --eyeIcon: #{$eyeIcon};
    --greySlow: #{$greySlow};
    --providerColor: #{$providerColor};
    --hoverColor: #{$hoverColor};
    --pressColor: #{$pressColor};
    --disableColor: #{$disableColor};
    --disableBg: #{$disableBg};
    --placeholder: #{$placeholder};
    --shadowColor: #{$shadowColor};
    --darkColor: #{$darkColor};
    --submenuColor: #{$submenuColor};
    --infoTextColor: #{$infoTextColor};
    --dropdownBg: #{$dropdownBg};
    --black0F: #{$black0F};
    --black: #{$black};
    --darkDropBG: #{$darkDropBG};
    --unavailable: #{$unavailable};
    --seeMoreBorder: #{$seeMoreBorder};
    --depositColor: #{$depositColor};
    --inboxActiveItemColor: #{$inboxActiveItemColor};
    --indicatorRed: #{$indicatorRed};
}
.dark_theme {
    --siteColor: #{$darkSiteColor};
    --siteBG: #{$darkSiteBg};
    --white: #{$black};
    --greySlow: #{$greySlow};
    --darkDropBG: #{$darkDropBG};
}