@import './default-scss/variables';
@import './default-scss/fonts';
@import './default-scss/mixins';
@import './default-scss/colors';
@import './default-scss/extendsStart';

html {
    height: 100%;
    font-size: 10px;
    line-height: 1.4286em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 100%;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    font-family: $regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include color($textColor,--textColor);
    @include background($bodyBg,--bodyBg);
    @media #{$until1199} {
      &.menu_opened {
        overflow: hidden;
      }
    }
    &:after {
      position: fixed;
      content: "";
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: $coverLayer;
      opacity: 0;
      @include animStyle(all 1s);
    }
    &.popup_opened:after {
      z-index: 500;
      opacity: 0.5;
      @include animStyle(all 0.5s);
    }
  &::-webkit-scrollbar {
    @include background($siteBg, --siteBg);
    width: 2px;
    visibility: visible;
  }

  &::-webkit-scrollbar-track {
    @include background($lightBg, --lightBg);
  }

  &::-webkit-scrollbar-thumb {
    @include background($siteColor, --siteColor);
  }
  &::-webkit-scrollbar:hover {
    width: 6px;
    visibility: visible;
  }
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

input, select, textarea, button, a, .slick-slide {
    outline: none !important;
}
input {
  font-family: $regular;
}
a,
a:focus,
a:hover,
button:hover {
    text-decoration: none;
}
button {
    cursor: pointer;
    display: block;
    font-family: inherit;
    letter-spacing: normal;
    -webkit-tap-highlight-color: transparent;
}
img	{
    max-height: none;
    border: none;
}

.custom_container {
    width: 100%;
    max-width: $containerWidth;
    padding: 0 $sideSpaceNew;
    margin: 0 auto;

    &:has(.inbox_preloader) {
      padding: 0;
    }

    @media #{$until413} {
        padding: 0 $sideSpaceMd;
    }
}
.narrow_container {
  width: 100%;
  max-width: 676 + 2*$sideSpace;
  padding: 0 $sideSpace;
  margin: 0 auto;
  @media #{$until413} {
      padding: 0 $sideSpaceMd;
  }
}

#root {
    padding-top: 54px;
    width: 100%;
    min-height: 100%;
    @include overHidden();
    z-index: 10;
    @media #{$until1199} {
        padding-top: 71px;
    }
}

.page_title {
    margin: 0;
    font-size: 240%;
    line-height: 1.375em;
    font-family: $bold;
    color: inherit;
}
.page_description {
  font-size: 120%;
  line-height: 17px;
  margin-top: $colSpaceSm;
  @include color($description,--description);
}

[class*="_page"] {
    padding-top: 50px;
    padding-bottom: 30px;
}

.standard_modal {
  overflow: hidden;
  text-align: center;
  background: rgba($bodyBg, 0.6);
  width: 100%;
  height: 100%;
  *{
    outline: none !important;
  }
  @include animStyle(all 0.3s);
  @include transStyle(scale(0));
  &.showed {
    @include transStyle(scale(1));
  }
}
.mod {
  background: lavender;
  display: inline-block;
}
.modal_inner{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y:auto;
    align-items: center;
    text-align: left;
    position: relative;
    > div {
      position: relative;
      z-index: 2;
    }

  &:before,
  &:after {
    display:block;
    content: "";
    flex: 1;
    min-height: $sideSpace;
  }

}
.popup_outside {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.MuiBackdrop-root {
  display: none !important;
}


.scrollbar-container {
    height: 100%;
    overflow: hidden;
    position: relative;
    touch-action: auto;
}
.root_inner > .help_btn {
    position: fixed;
    z-index: 50;
    @extend %button;
    @extend %middleButton;
    @extend %secondButton;
    @include animStyle(all $sidebarTrs !important);
    &:after {
      @include background($siteColor,--siteColor);
    }
    right: 2*$sideSpaceLg;
    bottom: 6px;
    top: auto;
    // top: 100%;
    // margin-top: - 2*$sideSpaceLg - 40;
    &:before {
        display: block;
        font-size: 200%;
        margin-right: $colSpaceMd;
        @include color($siteColor,--siteColor);
    }
    @media #{$until1559} {
      margin-top: - $colSpaceMd - 40;
      right: $colSpaceMd;
    }
    @media #{$until1199} {
        background: transparent;
        box-shadow: none;
        right: $sideSpace;
        // bottom: $sideSpaceSm;
        margin-top: -$sideSpaceSm - 40;
        flex-direction: column;
        padding: 0;
        z-index: 100;
        height: auto;
        //margin-bottom: -2px;
        border-radius: 0;
        overflow: visible;
        @include color($black,--black);
        &:after {
          display: none;
        }
        &:before {
            margin-right: 0;
            @include color($black,--black);
        }
    }
    @media #{$until413} {
        right: $sideSpaceMd;
    }
}
.blurEffect {

  filter: blur(2px);
  -webkit-filter: blur(2px);
  pointer-events: none;
}

  @media #{$until1199} {
    .root_inner {
      padding-bottom: 66px;
    }
  }
.no_data {
  &_block {
    padding: 7.5% $sideSpaceMd;
    text-align: center;
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include showAnimation();
  }
  &_icon {
    vertical-align: top;
    width: 180px;
    max-width: 60%;
    margin-bottom: $sideSpaceMd;
    display: inline-block;
  }
  &_title {
    font-size: 160%;
    line-height: 22px;
    max-width: 230px;
    margin: 0 auto $sideSpaceSm;
    font-family: $bold;
  }
  &_info {
    font-size: 120%;
    line-height: 17px;
    max-width: 230px;
    margin: 0 auto;
    opacity: 0.5;
  }
}
.currency_block {
  &:before {
    font-size: 85.7%;
  }
  &:not(:empty):before {
    display: none;
  }
}

.link_text_hide {
  font-size: 0 !important;
  line-height: 0;
}

@keyframes slowShowing {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@import './default-scss/extendsEnd';
