%standardList {
	@include standardList();
}
%coverLayer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

%centerBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

%verticalRotate {
	@include transStyle(scaleX(-1));
}
%horizontalRotate {
	@include transStyle(scaleY(-1));
}
%centeredImage {
> img {
	@extend %coverLayer;
	object-fit: cover;
}
}
%coverVideo {
	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100% !important;
		height: 100% !important;
	}
}
%square {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 100%;
	}
}
%almostSquare {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 75%;
	}
}
%lessWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 62.5%;
	}
}
%standardWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 56.25%;
	}
}
%halfWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 50%;
	}
}
%superWide {
	@include overHidden();
	@extend %centeredImage;
	@extend %coverVideo;
	&:before {
		display: block;
		content: "";
		padding-bottom: 25%;
	}
}
%standardNarrow {
	@include overHidden();
	@extend %centeredImage;
	&:before {
		display: block;
		content: "";
		padding-bottom: 177.78%;
	}
}
%lessNarrow {
	@include overHidden();
	@extend %centeredImage;
	&:before {
		display: block;
		content: "";
		padding-bottom: 133.33%;
	}
}

%iconElement {
	&:before,
	&:after {
		font-family: 'icon' !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

%button {
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	justify-content: center;
	text-align: center;
	@extend %all300;
	@include overHidden();
	@include siteBgHover;
}
%mainButton {
	@include background($siteColor, --siteColor);
	color: $black;
    border: 1px solid transparent;
    box-shadow: none;
	&[disabled] {
		background: $disableBg;
		color: $disableColor;
		pointer-events: none;
	}
	&:after {
		@include background($textColor,--textColor);
	}
}

%emptyButton {
	border: 1px solid;
	@include color($siteColor, --siteColor);
    background: transparent;
    box-shadow: none;
	&:after {
		@include background($siteColor, --siteColor);
	}
}
%emptyLightButton {
	border: 1px solid;
	@include color($infoTextColor, --infoTextColor);
    background: transparent;
    box-shadow: none;
	&:hover {
		@include color($siteColor !important,--siteColor !important);
		@include border($siteColor !important,--siteColor !important);
		background: transparent !important;
		box-shadow: none !important;

	}
	&:active {
		@include color($lightColor !important,--lightColor !important);
		@include border($lightColor !important,--lightColor !important);
		background: transparent !important;
		box-shadow: none !important;

	}
}
%secondButton {
	@include background($lightBg, --lightBg);
	border: none;
	@include color($textColor05, --textColor05);
	box-shadow: $shadowLighter;
	&:after {
		@include background($textColor, --textColor);
	}
}
%activeSecondButton {
	@include background($lightBgHover, --lightBgHover);
	@include color($lightColor, --lightColor);
	box-shadow: $shadowLight;
}

%smallestBtn {
	font-size: 100%;
	line-height: 14px;
	padding: 0 7px;
	border-radius: 9px;
}

%smallButton {
	font-size: 120%;
	line-height: 14px;
	height: 30px;
	padding: 0 8px;
	border-radius: 7px;
}

%smallWideButton {
	font-size: 120%;
	line-height: 14px;
	height: 45px;
	padding: 0 8px;
	border-radius: 7px;
	font-family: $bold;
}

%middleButton {
	font-size: 160%;
	line-height: 20px;
	height: 40px;
	padding: 0 16px;
	border-radius: 7px;
}
%middleBigBtn {
	font-size: 180%;
	line-height: 22px;
	height: 39px;
	padding: 0 15px;
	border-radius: 7px;
}
%largeButton {
	font-size: 200%;
	line-height: 30px;
	height: 46px;
	padding: 0 16px;
	border-radius: 6px;

}

%wideBtn {
	font-size: 120%;
	line-height: 15px;
	height: 45px;
	border-radius: 7px;
	font-family: $bold;
}

%field {
	@include placeholder {
		@include color($placeholder,--placeholder);
	}
	input {
		border: 1px solid transparent;
		@include background($lightColor, --lightColor);
		@include color($fieldColor, --fieldColor);
		border-radius: 10px;
		width: 100%;
		box-shadow: $shadowLight;
		height: auto;
		@extend %all300;
		box-sizing: border-box;
		&:focus {
			box-shadow: $shadowLighter;
			@include border($siteColor,--siteColor);
		}
	}
	.MuiInputBase-root {
		font-size: 100%;
	}
	.MuiInput-underline:after,
	.MuiInput-underline:before {
		display: none;
	}

}
%smallField {
	input {
		padding: $colSpaceMd $sideSpaceLg;
		line-height: 14px;
	}
	.clear_btn {
		top: 32px;
	}
}
%middleField {
	input {
		font-size: 120%;
		line-height: 14px;
		padding: $sideSpaceSm $sideSpace;
	}
	.clear_btn {
		top: 36px;
	}
}
%largeField {
	input {
		font-size: 120%;
		line-height: 14px;
		padding: $sideSpaceMd $sideSpace;
	}
	.clear_btn {
		top: 40px;
	}
}
%selectItems {
	.select_ {
		&_menu {
			top: 100%;
			@include background($lightColor,--lightColor);
			box-shadow: $shadowLight;
			margin: 0;
			z-index: 20;
			border-radius: 0 0 $colSpaceSm $colSpaceSm;
			max-width: 100%;
			padding-bottom: $sideSpaceSm;
		}
		&_menu-list {
			padding: 0;
			max-height: 160px;

			&::-webkit-scrollbar {
				@include background($siteBg,--siteBg);
				width: 6px;
			}
			&::-webkit-scrollbar-track {
				@include background($scrollTrack,--scrollTrack);
			}

			&::-webkit-scrollbar-thumb {
				@include background($siteColor,--siteColor);
			}
		}
		&_option:hover {
			@include background($timeBg,--timeBg);
		}
		&_option {
			font-size: 100%;
			line-height: 14px;
			padding: $colSpace $sideSpaceLg;
			@include color($fieldColor,--fieldColor);
			outline: none;
			border: none;
			cursor: pointer;
			@extend %all300;
			background: transparent;
			word-break: break-word;
			&--is-selected {
				@include background($timeBg,--timeBg);
			}
		}
	}

}
%select {
	.select_ {
		&_picker {
			outline: none !important;
		}
		&_single-value {
			margin: 0 1px;
			color: inherit;
			padding-right: 20px;
			overflow: visible;
		}
		&_control {
			max-width: 100%;
			min-height: 0;
			@include background($lightColor,--lightColor);
			border-radius: 10px;
			border: none;
			cursor: pointer;
			outline: none;
			box-shadow: $shadowLight;
			@include animStyle(none !important);
			&--menu-is-open {
				border-bottom-left-radius:  0 !important;
				border-bottom-right-radius: 0 !important;
				.select__indicator {
					@extend %horizontalRotate;
				}
			}
		}
		&_value-container {
			line-height: 14px;
			font-size: inherit;
			font-family: inherit;
			// padding: $colSpaceSm $sideSpaceLg;
			@include color($fieldColor,--fieldColor);
			height: 100%;

		}
		&_placeholder {
			@include color($placeholder,--placeholder);
			margin: 0;
		}
		&_indicator-separator {
			display: none;
		}
		&_indicator {
			svg {
				display: none;
			}
			padding: 0;
			margin-right: $sideSpaceLg;
			@extend %iconElement;
			@include color($eyeIcon,--eyeIcon);
			font-size: 0.8rem;
			&:before {
				display: block;
				content: "\e951";
			}
		}
	}
	@extend %selectItems;
}

%customScroll {
	.ps--active-y > .ps__rail-y {
		width: 8px !important;
		@include background($lightBg,--lightBg);
		&:before {
			display: block;
			width: 2px;
			height: 100%;
			content: "";
			margin: 0 auto;
			border-radius: $colSpaceMd;
			@include background($scrollTrack,--scrollTrack);
		}
	}
	.ps__thumb-y {
		background: transparent !important;
		width: 100% !important;
		right: 0;
		cursor: pointer;
		opacity: 1;
		&:before {
			display: block;
			width: 2px;
			height: 100%;
			content: "";
			margin: 0 auto;
			border-radius: $colSpaceMd;
			@include background($siteColor,--siteColor);
		}
	}
	.ps:hover > .ps__rail-x,
	.ps:hover > .ps__rail-y,
	.ps--focus > .ps__rail-x,
	.ps--focus > .ps__rail-y,
	.ps--scrolling-x > .ps__rail-x,
	.ps--scrolling-y > .ps__rail-y {
		opacity: 1;
	}
}
%customScrollX {
	.ps--active-x > .ps__rail-x {
		height: 8px !important;
		@include background($lightBg,--lightBg);
		&:before {
			display: block;
			height: 2px;
			width: 100%;
			content: "";
			margin: 3px 0;
			border-radius: $colSpaceMd;
			@include background($scrollTrack,--scrollTrack);
		}
	}
	.ps__thumb-x {
		background: transparent !important;
		height: 100% !important;
		bottom: 0;
		cursor: pointer;
		opacity: 1;
		&:before {
			display: block;
			height: 2px;
			width: 100%;
			content: "";
			margin: 3px 0;
			border-radius: $colSpaceMd;
			@include background($siteColor,--siteColor);
		}
	}
	.ps:hover > .ps__rail-x,
	.ps:hover > .ps__rail-y,
	.ps--focus > .ps__rail-x,
	.ps--focus > .ps__rail-y,
	.ps--scrolling-x > .ps__rail-x,
	.ps--scrolling-y > .ps__rail-y {
		opacity: 1;
	}
}

%noBlink {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

%link {
	@include color($siteColor,--siteColor);
	font-family: $bold;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 22px;
	text-decoration-line: underline;
}
