@import '../../../default-scss/variables';
@import '../../../default-scss/mixins';
@import '../../../default-scss/extendsStart';

@keyframes rightAnimate {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 22px;
  }
  51% {
    margin-left: -22px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes leftAnimate {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -30px;
  }
  51% {
    margin-left: 10px;
  }
  100% {
    margin-left: 0;
  }
}

.sidebar_menu {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  padding-right: 21px;
  @include background($lightBg, --lightBg);
  top: 0;
  left: 0;
  width: $sideBarWidth;
  height: 100%;
  box-shadow: $shadowDark;
  @include animStyle(width $sidebarTrs);

  .sidebar_inner::-webkit-scrollbar {
    @include background($siteBg, --siteBg);
    width: 2px;
    visibility: visible;
  }

  .sidebar_inner::-webkit-scrollbar-track {
    @include background($lightBg, --lightBg);
  }

  .sidebar_inner::-webkit-scrollbar-thumb {
    @include background($siteColor, --siteColor);
  }


  + .root_inner {
    padding-left: $sideBarWidth;
    @include animStyle(padding $sidebarTrs);

    .header {
      padding-left: $sideBarWidth;
      @include animStyle(padding $sidebarTrs);
    }
  }

  .sidebar_btn:before {
    @include transStyle(scaleX(1));
  }

  .main_logo {
    margin: 0 11px $sideSpace;
    @include overHidden();
    @include animStyle(margin 0.5s linear);
    height: 50px;

    img {
      display: inline-block;
      vertical-align: top;
      height: 50px;
      margin-left: 4px;
      width: auto;
      @include animStyle(all 0.5s linear);
      @include torigin(left center);
    }

    a {
      display: inline-block;
      vertical-align: top;
    }
  }

  .reg_bonus {
    background-size: $sideBarInnerWidth auto;
    background-position: left top;
    background-repeat: no-repeat;
    padding-top: $sideSpaceMd;
    padding-bottom: 2*$sideSpaceMd;
    text-align: center;
    @include overHidden();

    img {
      @include overHidden();
      vertical-align: top;
      width: 35px;
      height: 35px;
      object-fit: cover;
      border-radius: 50%;
      @include animStyle(all $sidebarTrs);
    }

    .bonus_description {
      font-size: 140%;
      line-height: 19px;
      padding: $sideSpaceMd 2*$sideSpaceMd 0;
      width: $sideBarInnerWidth;
      @include overHidden();
      left: 50%;
      @include transStyle(translate3d(-50%, 0, 0));
      @include animStyle(all $sidebarTrs);
      height: 0;
      opacity: 0;
    }

    .about_bonus {
      font-family: $bold;
    }
  }

  .sign_buttons {
    @include overHidden();
    padding: 0 $sideSpaceLg;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: -20px;
    @include animStyle(opacity 0.25s linear);
    @media screen and (min-width: 1200px) {
      @include animDelay(0s);
      opacity: 0;
    }

    button {
      pointer-events: none;
    }
  }

  .login_button {
    @extend %emptyButton;
    @extend %middleButton;
    max-width: 125px;
    width: 100%;
  }

  .register_button {
    @extend %mainButton;
    @extend %middleButton;
    margin-bottom: $sideSpaceSm;
    max-width: 204px;
    width: 100%;
    @include overHidden();
  }

  .search_block {
    padding: $sideSpaceMd $sideSpaceLg;
    @include background($timeBg, --timeBg);
    z-index: 20;

    input {
      font-size: 120%;
      padding: $sideSpaceSm 2*$sideSpace $sideSpaceSm $sideSpaceLg;
      @include background($lightBg, --lightBg);
      border-radius: $sideSpace;
    }

    .clear_btn {
      right: $sideSpace;
      top: $sideSpaceMd;
      height: 40px;
      padding: 0 $sideSpaceSm;
      z-index: 5;
    }

    &.showed {
      + .sign_block {
        opacity: 0;
      }

      ~ .sidebar_bottom {
        .main_menu,
        .second_menu {
          opacity: 0;
        }
      }
    }

    .results_list {
      margin-top: 0;
      box-shadow: none;

      &:before {
        display: none;
      }

      ul {
        background: transparent;

        li {
          border: none;

          .menu_item {
            font-size: 160%;
            line-height: 20px;
          }

          img {
            margin-right: $sideSpaceMd;
          }

          button {
            border: 1px solid;
            @include color($siteColor, --siteColor);
            background: transparent;
            font-size: 120%;
          }
        }
      }

      .scrollbar-container {
        max-height: 50vh;
        min-height: 240px;
        height: 240px;
      }

      .more_link {
        padding-top: $sideSpace;
      }

    }
  }

  .profile_block {
    margin-bottom: $sideSpace;
    padding: 0 $sideSpaceLg;

    > .tournament_btn,
    > .deposit_btn,
    > .sports_book_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -12px;
      margin-right: -12px;
      @extend %middleButton;
      @include background($textColor, --textColor);
      @include color($lightBg, --lightBg);
      font-size: 140%;
      border-radius: 8px;
      height: 53px;
      position: relative;
      margin-bottom: 10px;
      cursor: pointer;

      &:before {
        font-size: 2rem;
        margin-right: 0;
      }

      span {
        max-width: 0;
        text-indent: 10px;
        overflow: hidden;
        position: relative;
        @include animStyle(all 0.5s linear);
      }

      &:hover {
        @include background($siteColor, --siteColor);
        @include color($lightColor, --lightColor);
      }
    }

    > .deposit_btn:before {
      font-size: 180%;
    }

    .buttons_block {
      height: 138px;
      position: relative;
      margin: 0 #{-$colSpaceSm};
      @include animStyle(height $sidebarTrs);

      .inner_block {
        padding: $sideSpaceSm $colSpaceSm 0;
        position: absolute;
        top: 0;
        left: -12px;
        right: -12px;
        @include animStyle(all 0.5s linear);

        .icon_profile:before {
          font-size: 2.6rem;
        }

        .icon_gift:before {
          font-size: 2.4rem;
        }

        span {
          font-size: 0;
          line-height: 0;
          display: block
        }

        &:last-child {
          margin-top: 63px;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include overHidden();
        border-radius: $colSpaceMd;
        box-shadow: $shadowMiddle;
        z-index: 2;
        padding: $sideSpace $sideSpaceMd $sideSpaceMd;
        text-align: center;
        font-size: 140%;
        line-height: 18px;
        height: 93px;
        white-space: nowrap;
        @include color($lightBg, --lightBg);

        @include background($textColor, --textColor);
        //@include color($black,--black);
        @include animStyle(all 0.25s linear);
        @include animDelay(0.25s);

        &:before {
          // @include background($siteColor,--siteColor);
          font-size: 1.8rem;
          display: block;
          margin: 0 -2px 3px;
          padding: 0;
          color: inherit;
        }

        &:hover {
          @include background($siteColor, --siteColor);
          @include color($lightColor, --lightColor);
        }

        &.active {
          cursor: default;
          @include color($white, --white);
          @include background($siteColor, --siteColor);
          pointer-events: none;

          &:after {
            width: 120%;
            padding-bottom: 100%;
            opacity: 1;
            min-height: 100%;
            border-radius: 0;
          }
        }
      }
    }

    @media screen and (min-width: 1200px) {
      .buttons_block {
        height: 138px;

        a {
          padding: 0 5px;
          height: 53px;
          @include animDelay(0s);
        }
      }


    }
  }

  .user_block {
    background-size: $sideBarInnerWidth auto;
    background-position: top left;
    background-repeat: no-repeat;
    margin: 0 -12px;
    padding: $sideSpaceMd $sideSpaceSm $sideSpace;
    border-bottom: 2px solid transparent;
    height: 86px;
    @include overHidden();
    @include ltrAfter($fieldBg, --fieldBg, 0.2);
    @extend %linkHover;

    &:after {
      pointer-events: none;
    }

    .deposit_btn {
      margin-left: $sideSpaceMd;
    }
  }

  .user_image {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-left: 2px;
    @include animStyle(all 0.5s linear);
  }

  @media #{$since1200} {
    .main_menu {
      li:before {
        width: 34px;
        @include animStyle(width $sidebarTrs);
      }

      .menu_item {
        color: transparent;
        @include animStyle(all 0.25s linear);
        @include animDelay(0s);
        width: 40px;
        height: 40px;
        margin-left: 20px;
        margin-bottom: 10px;
        padding: 0;
        border-radius: 6px;
        position: relative;
        z-index: 1;

        &:before {
          font-size: 2.4rem;
          padding-left: 8px;
          @include animStyle(all 0.5s linear);
          position: relative;
          z-index: 2;
        }

      }
    }
  }

  &.sidebar_toggle {
    width: $sideBarFullWidth;

    .sidebar_inner {
      .inner_content {
        .sign_block {
          .deposit_btn,
          .tournament_btn,
          .sports_book_btn {
            .btn_text {
              max-width: 70%;
            }
          }
        }
      }
    }

    .second_menu {
      .menu_subitem {
        &.active {
          &:before {
            content: '';
            width: 100%;
            border-left: 2px solid $siteColor;
            height: 100%;
            position: absolute;
            left: 17px;
            top: 0;

          }
        }
      }
    }

    .main_menu .menu_item.current {
      @include color($siteColor, --siteColor);
    }

    .main_logo {
      margin-left: 30px;

      img {
        margin-left: 0;
      }
    }

    @media screen and (min-width: 1440px) {
      + .root_inner {
        padding-left: $sideBarFullWidth;

        .header {
          padding-left: $sideBarFullWidth;
        }

        .game_innerpage {
          left: $sideBarFullWidth;
        }

        .promotion_inner {
          margin-left: -$sideBarFullWidth;
        }
      }
    }

    .sidebar_btn {
      &:before {
        @extend %verticalRotate;
      }

      &:hover:before {
        animation: leftAnimate;
        animation-duration: 0.5s;
      }
    }

    @media screen and (min-width: 1200px) {
      .reg_bonus {
        img {
          width: 69px;
          height: 69px;
        }

        .bonus_description {
          height: 60px;
          padding-bottom: $sideSpaceSm;
          opacity: 1;
        }
      }
      .sign_buttons {

        @include animDelay(0.25s);
        opacity: 1;

        button {
          pointer-events: inherit;
        }
      }
      .second_menu {
        .menu_item {
          @include color($textColor, --textColor);
        }

        .menu_subitem {
          @include color($submenuColor, --submenuColor);
        }

        .menu_item,
        .menu_subitem {

          @include animDelay(0.25s);

          &.active {
            @include color($white, --white);

            &:before {
              content: '';
              width: 100%;
              border-left: 3px solid $siteColor;
              height: 100%;
              position: absolute;
              left: 22px;
              margin-left: -3px;
              top: 0;

            }

            &:after {
              width: 100%;
              height: 100%;
              opacity: 0.1;
              // @include background($siteColor,--siteColor);
            }
          }

        }
      }
      .sidebar_footer {
        .copyrights {
          @include color($textColor05, --textColor05);
          @include animDelay(0.25s);
        }
      }
      .affiliate_business .button_block a .item_inner {
        @include animDelay(0.25s);
        font-size: 240%;
      }
      .user_image {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }
      .profile_block > .deposit_btn {
        &:before {
          z-index: 1;
          position: relative;
        }


      }
      .buttons_block {
        height: 92px;

        .inner_block {
          &:first-child {
            left: 0;
            right: 50%;
          }

          &:last-child {
            right: 0;
            left: 50%;
            margin-top: 0;
          }
        }

        a {
          padding: 0 $sideSpaceMd;
          height: 70px;

          @include animDelay(0.25s);

          &:before {
            margin-bottom: 3px;
          }
        }
      }
      .main_menu {
        li:before {
          width: 204px;
        }

        .menu_item {
          color: inherit;
          margin: 0;
          padding: 0 $sideSpaceLg;
          width: 100%;

          &:before {
            font-size: 1.6rem;
            min-width: 26px;
            padding: 0;
          }

          @include animDelay(0.25s);

          &.active {
            @include color($siteColor, --siteColor);
          }
        }
      }
    }
  }
}

.sidebar_btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 21px;
  height: 100%;
  border: none;
  border-left: 1px solid;
  background: inherit;
  padding: 0;
  font-size: 120%;
  @include border($detailsBorder, --detailsBorder);
  @include color($white, --white);

  &:before {
    display: block;
    text-align: center;
    @include animStyle(transform $sidebarTrs);
    @include transStyle(scaleX(-1));
  }

  &:after {
    @include psevdoAbs();
    left: 0;
    width: 100%;
    top: 50%;
    margin-top: -22px;
    height: 44px;
    @include background($lightBg, -- $lightBg);
    opacity: 0.12;
    z-index: 2;
  }

  &:hover:before {
    animation: rightAnimate;
    animation-duration: 0.5s;

  }

  @media #{$since1200} {
    overflow: hidden;
  }
}

.sidebar_inner {
  height: 100%;

  .inner_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding-top: $sideSpaceLg;

    .main_logo + .sign_block {
      > .tournament_btn,
      > .sports_book_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        margin-right: 12px;
        @extend %middleButton;
        @include background($textColor, --textColor);
        @include color($lightBg, --lightBg);
        font-size: 140%;
        border-radius: 8px;
        height: 40px;
        position: relative;
        margin-bottom: 10px;

        &:before {
          font-size: 2rem;
          margin-right: 0;
        }

        span {
          max-width: 0;
          text-indent: 10px;
          overflow: hidden;
          position: relative;
          @include animStyle(all 0.5s linear);
        }

        &:hover {
          @include background($siteColor, --siteColor);
          @include color($lightColor, --lightColor);
        }
      }
    }

    @media #{$since1200} {
      min-height: 0;
      height: 100%;
    }
  }
}

.sidebar_bottom {
  display: flex;
  flex: 1;
  height: 40%;
  flex-direction: column;
  justify-content: flex-end;
  @extend %customScroll;

  .menues_container {
    flex: 1;
    height: 40px;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    @include customScroll(3px, auto, thin, 0, 0, $lightBg, $siteColor);
  }

  .menues_list {
    display: flex;
    width: 100%;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-end;

    &.hide {
      display: none;
    }

    &.closedSidbar {
      .main_menu {
        li:before {
          width: 34px;
          @include animStyle(width $sidebarTrs);
        }

        .menu_item {
          color: transparent;
          @include animStyle(all 0.25s linear);
          @include animDelay(0s);
          width: 40px;
          height: 30px;
          margin-left: 20px;
          margin-bottom: 10px;
          padding: 0;
          border-radius: 6px;
          position: relative;
          z-index: 1;

          &:before {
            font-size: 1.6rem;
            padding-left: 8px;
            @include animStyle(all 0.5s linear);
            position: relative;
            z-index: 2;
          }

        }
      }
    }
  }
}

@media #{$until1199} {
  .sidebar_menu {
    width: 0;
    padding: 0;
    overflow: visible;

    + .root_inner {
      padding-left: 0;
      @include animStyle(margin $sidebarTrs);
      width: 100vw;

      .header {
        padding-left: 0;
        @include animStyle(margin $sidebarTrs);
        width: 100vw;
      }
    }

    &.sidebar_toggle {
      width: $sideBarMobile;

      .sidebar_btn {
        padding-left: $sideSpaceSm;

        &:after {
          width: 100vw;
          opacity: 0.5;
        }
      }

      + .root_inner {
        padding-left: 0;
        margin-left: $sideBarMobile;

        .header {
          padding-left: 0;
          margin-left: $sideBarMobile;

          .sign_buttons:after,
          .register_button {
            margin-left: $sideBarMobile;
          }
        }

        .games_page .filter_buttons,
        > .help_btn {
          margin-right: -$sideBarMobile;
        }

        .games_page .navigation_block,
        .bottom_menu .menu_list {
          margin-left: $sideBarMobile;
        }

        .game_innerpage {
          left: $sideBarMobile;
        }

        .promotion_inner {
          margin-left: -$sideBarMobile;
        }
      }
    }

    .main_logo {
      position: relative;
      padding: 3px;
      text-align: center;
      margin-left: 0 !important;
      margin-right: 0 !important;

      a, img {
        position: relative;
        display: inline-block;
        vertical-align: top;
        z-index: 5;
        height: 56px;
      }

      &:before {
        @include psevdoAbs();
        top: -2px;
        left: 50%;
        margin-left: -30px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid;
        @include border($detailsBorder, --detailsBorder);
      }
    }

    .sign_block {
      padding: 2*$sideSpaceMd $sideSpaceLg;
    }

    .sign_buttons {
      margin: 0;

      button {
        font-size: 160%;
        line-height: 22px;
        height: 48px;
        padding: 0 $sideSpace;
        pointer-events: inherit;
      }
    }

    .register_button {
      margin-bottom: 2*$sideSpaceMd;
      min-width: 180px;
      max-width: 100%;
      width: auto;
    }

    .login_button {
      min-width: 117px;
      max-width: 100%;
      width: auto;
    }
  }
  .sidebar_inner {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .inner_content {
      padding: 0;
      width: $sideBarMobile;
      float: right;
    }
  }
  .sidebar_btn {
    top: 0;
    left: 100%;
    background: transparent;
    border: none;
    height: auto;
    padding: 4+$sideSpace 0 4+$sideSpace $sideSpace;
    width: 50px;
    @include animStyle(padding $sidebarTrs);

    &:before {
      display: none;
    }

    &:after {
      height: 100vh;
      top: 0;
      margin-top: 0;
      width: 0;
      z-index: 5;
      opacity: 0;
      @include background($coverLayer, --coverLayer);
      @include animStyle(opacity $sidebarTrs);
    }

    .burger_lines {
      display: block;
      @include color($siteColor, --siteColor);
      width: 26px;
      border-style: solid;
      border-width: 2px 0;
      padding: $colSpaceSm 0;

      &:before {
        display: block;
        content: "";
        border-top: 2px solid;
      }
    }
  }
}

@media #{$until413} {
  .sidebar_menu {
    &.sidebar_toggle {
      width: $sideBarMobileMd;

      + .root_inner {
        padding-left: 0;
        margin-left: $sideBarMobileMd;

        .header {
          padding-left: 0;
          margin-left: $sideBarMobileMd;

          .sign_buttons:after,
          .register_button {
            margin-left: $sideBarMobileMd;
          }
        }

        .games_page .filter_buttons,
        > .help_btn {
          margin-right: -$sideBarMobileMd;
        }

        .games_page .navigation_block,
        .bottom_menu .menu_list {
          margin-left: $sideBarMobileMd;
        }

        .game_innerpage {
          left: $sideBarMobileMd;
        }

        .promotion_inner {
          margin-left: -$sideBarMobileMd;
        }
      }
    }

    .search_block .results_list {
      ul li {
        img {
          margin-right: $sideSpaceSm;
        }

        .menu_item {
          font-size: 140%;
          line-height: 18px;
        }

        button {
          margin-left: $sideSpaceSm;
          padding: 0 $sideSpaceSm;
        }
      }
    }

  }
  .sidebar_inner {
    .inner_content {
      width: $sideBarMobileMd;
    }
  }
  .sidebar_btn {
    padding: 4+$sideSpace 0 4+$sideSpace $sideSpaceMd;
  }
}

@media #{$until359} {
  .sidebar_menu {
    &.sidebar_toggle {
      width: $sideBarMobileSm;

      .sidebar_btn {
        padding-left: $colSpaceMd;

        &:after {
          width: 100vw;
        }
      }

      + .root_inner {
        padding-left: 0;
        margin-left: $sideBarMobileSm;

        .header {
          padding-left: 0;
          margin-left: $sideBarMobileSm;

          .sign_buttons:after,
          .register_button {
            margin-left: $sideBarMobileSm;
          }
        }

        .games_page .filter_buttons,
        > .help_btn {
          margin-right: -$sideBarMobileSm;
        }

        .games_page .navigation_block,
        .bottom_menu .menu_list {
          margin-left: $sideBarMobileSm;
        }

        .game_innerpage {
          left: $sideBarMobileSm;
        }

        .promotion_inner {
          margin-left: -$sideBarMobileSm;
        }
      }
    }

    .search_block .results_list {
      ul li {
        img {
          width: 48px;
          min-width: 48px;
          height: 48px;
          margin-right: $colSpace;
        }

        .menu_item {
          line-height: 16px;
          font-size: 120%;
          padding-top: 0;
        }

        button {
          margin-left: $colSpace;
        }
      }
    }
  }
  .sidebar_inner {
    .inner_content {
      width: $sideBarMobileSm;
    }
  }
  .sidebar_btn {
    padding: 4+$sideSpace 0 4+$sideSpace $sideSpaceMd;
  }
}

@media #{$since1200} {
  .sidebar_menu {
    .inner_content {
      padding-top: $sideSpaceMd;
    }

    .main_logo {
      margin-bottom: $sideSpaceMd;
    }

    .second_menu {
      .menu_item {
        height: 38px;
      }

      .menu_subitem {
        min-height: 38px;
      }
    }

    .main_menu {
      .menu_item {
        height: 40px;
      }
    }

    .user_block {
      padding-bottom: $sideSpaceLg;
      height: 172px;
      position: relative;
      align-items: flex-start;
      @include animStyle(height 0.5s linear);

      .deposit_btn {
        width: 40px;
        height: 40px;
        min-width: 40px;
        position: absolute;
        top: $sideSpaceMd + 102;
        right: 2px + $sideSpaceSm;
        margin: 0;
        @include animStyle(all 0.5s linear !important);
      }

      .name_balance {
        position: absolute;
        flex: none;
        width: auto;
        font-family: $medium;
        left: 0;
        right: 0;
        top: $sideSpaceMd + 55;
        padding: 0 6px;
        @include animStyle(all 0.5s linear);
      }

      .user_name {
        font-size: 140%;
        line-height: 18px;
        max-width: 100%;
        @include overHidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        @include color($textColor, --textColor);
      }

      .user_balance {
        @include color($siteColor, --siteColor);
        float: none;
        width: 98%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        margin-right: auto;
        text-align: left;

        &:after {
          display: block;
          content: "";
          flex: 0;
          @include animStyle(all 0.5s linear);
        }
      }
    }

    .profile_block {
      margin-bottom: $sideSpaceLg;
    }

    &.sidebar_toggle {
      .user_block {
        height: 70px;

        .user_image {
          margin: 0;
        }

        .deposit_btn {
          top: $sideSpaceMd;
          right: $sideSpaceSm;
        }

        .name_balance {
          left: 48 + $sideSpaceSm;
          right: 48 + $sideSpaceSm;
          top: $sideSpaceMd;
          margin-top: 2px;
          max-width: 115px;
        }

        .user_balance:after {
          flex: 5;
        }
      }

      .profile_block {
        .buttons_block {
          .inner_block {
            span {
              display: block;
            }
          }
        }

        > .deposit_btn, .tournament_btn, .sports_book_btn {
          margin-left: 0;
          margin-right: 0;
          height: 30px;

          div {
            padding-top: 0;
            padding-left: 38px;

            .btn_text {
              display: block;
            }

            span {
              &:before {
                width: 26px;
              }
            }
          }

        }

        > .tournament_btn, .sports_book_btn {
          div {
            span {
              &:before {
                left: 6px;
                top: -1px;
                font-size: 2.2rem;
              }
            }
          }
        }
      }
    }
  }
}

.display_none {
  display: none !important;

  + .root_inner {
    padding-left: 0 !important;

    .header {
      padding-left: 0 !important;

      .main_logo + .tournament_btn button,
      .notifications {
        margin-left: 0
      }

    }
  }
}

@import '../../../default-scss/extendsEnd';
