@import '../../../default-scss/variables';
@import '../../../default-scss/mixins';
@import '../../../default-scss/extendsStart';

@keyframes searchBA {
    0% {
        box-shadow: -6px 0 0 0, 6px 0 0 0;
    }
    40% {
        box-shadow: -6px 0 0 0, 6px 0 0 0;
    }
    50% {
        box-shadow: -6px -2px 0 0, 6px 0 0 0;
    }
    60% {
        box-shadow: -6px 0 0 0, 6px 0 0 0;
    }
    80% {
        box-shadow: -6px 0 0 0, 6px 0 0 0;
    }
    90% {
        box-shadow: -6px 0 0 0, 6px -2px 0 0;
    }
    100% {
        box-shadow: -6px 0 0 0, 6px 0 0 0;
    }
}
@keyframes searchAA {
    0% {
        box-shadow: -4px 0 0 0;
    }
    60% {
        box-shadow: -4px 0 0 0;
    }
    70% {
        box-shadow: -4px -2px 0 0;
    }
    80% {
        box-shadow: -4px 0 0 0;
    }
    100% {
        box-shadow: -4px 0 0 0;
    }
}

.search_block {
    position: relative;
    @include placeholder {
        @include color($placeholder,--placeholder);
    }
    input {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: $colSpaceMd 38px $colSpaceMd $sideSpaceLg;
        // border: 1px solid transparent;
        background: transparent;
        border: 1px solid rgba($textColor,0.3);
        // opacity: 0.3;
        border-radius: 16px;
        line-height: 14px;
        box-shadow: $shadowLight;
        font-size: 130%;
        @include color($fieldColor,--fieldColor);
        // @include background($lightBg,--lightBg);
        @extend %all300;
        &:focus {
            box-shadow: $shadowLighter;
            @include border($siteColor,--siteColor);
            border: 1px solid rgba($textColor, 0.5);
        }
        &::placeholder{
            color: rgba($textColor, 0.4);
            font-size: 110%;
            line-height: 21px;
            mix-blend-mode: normal;
        }    
    }
   
    .clear_btn {
        border: none;
        background: transparent;
        right: $sideSpaceSm;
        top: $colSpace;
        padding: 0 3px;
        position: absolute;
        z-index: 5;
        height: 14px;
        @include color($textColor,--textColor);
        &.icon_loop {
            font-size: 120%;
            pointer-events: none;
        }
        &.icon_close {
            font-size: 80%;
            @include animStyle(transition 0.5s);
            @extend %scaleHover;
        }
        &:before {
            display: block;
        }
        &.searching {
            pointer-events: none;
            display: flex;
            &:before,
            &:after {
                display: block;
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-duration: 1s;
            }
            &:before {
                box-shadow: -6px 0 0 0, 6px 0 0 0;
                animation-name: searchBA;
                
            }
            &:after {
                box-shadow: -4px 0 0 0;
                animation-name: searchAA;
            }
        } 
    }
    &.mobile_search {
        margin-bottom: $colSpaceSm;
        border-radius: $sideSpace;
        input {
            font-size: 120%;
            padding: $sideSpaceSm 2*$sideSpace $sideSpaceSm $sideSpaceLg;
            border-radius: $sideSpace;
        }
        .clear_btn {
            padding: 0 $colSpaceSm;
            top: $sideSpaceSm + 1;
        }
    }
}

@import '../../../default-scss/extendsEnd';
