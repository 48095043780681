@use "sass:meta";

//window sizes
$regular: "source_sans";
$bold: "source_sans_bold";
$medium: "source_sans_medium";
$light: "light";
$fontHelveticaRegular: "helevetica";
$until1559: "(max-width: 1559px)";
$until1439: "(max-width: 1439px)";
$until1279: "(max-width: 1279px)";
$until1199: "(max-width: 1199px)";
$until1023: "(max-width: 1023px)";
$until991: "(max-width: 991px)";
$until959: "(max-width: 959px)";
$until767: "(max-width: 767px)";
$until639: "(max-width: 639px)";
$until575: "(max-width: 575px)";
$until479: "(max-width: 479px)";
$until420: "(max-width: 420px)";
$until413: "(max-width: 413px)";
$until359: "(max-width:359px)";
$since1200: "(min-width: 1200px)";
//size variables
$containerWidth: 1900px;
$sideSpaceMx: 30px; // New Var
$sideSpace: 24px;
$sideSpaceNew: 15px;
$logRegSpace: 25px 30px;
$sideSpaceLg: 18px;
$sideSpaceMd: 16px;
$sideSpaceSm: 12px;
$colSpace: 9px;
$colSpaceMd: 8px;
$colSpaceSm: 6px;
$sideBarWidth: 101px;
$sideBarFullWidth: 301px;
$sideBarInnerWidth: 280px;
$sideBarMobile: 366px;
$sideBarMobileMd: 310px;
$sideBarMobileSm: 280px;
// standard colors
$black: #000000;
$black33: #333333;
$black0F:#0F111B;
$gray66: #666666;
$gray99: #999999;
$grayE5: #e5e5e5;
$grayD8:#D8D8D8;
$white: #ffffff;
$creamyF2:#f2f2f2;
$creamy77:#778090;
$eyeIcon:#343A40;
$greySlow: #979797;
$providerColor: #B9B7AC;
$darkColor: $black;
$unavailable: #e0e0e0;
$tabTextColor: #DEDCCB;

//main theme colors
$darkDropBG: #121520;
$siteColor: #FFAE11;
$siteColorHover: #FFAE11;
//$siteColor: #FF472E;
$hoverColor: #FFBF4F;
$pressColor: #FFBD45;
$secondaryButton: #EAAA37;
$siteColor007: rgba($siteColor,0.07);
$siteBg: rgba($white,0.88);
$textColor: #EAE7D6;
$textColor05: rgba($textColor,0.5);
$textColor07: rgba($textColor,0.7);
$lightBg: #171922;
$lightColor: #ffffff;
$lightBgHover: #B2B2B2;
$indicator: #FFAE11;;
$indicatorBorder: #F7F7F7;
$notBtnBg: #F1F4F8;
$logoRound: #d5d5d5;
$timeBg: rgba(#D0D9E7,0.3);
$fieldColor: #707070;
$fieldPh: rgba($fieldColor,0.5);
$standardBorder: #040507;
$detailsBorder: rgba(#8e8e93,0.15);
$userBg: rgba($standardBorder, 0.54);
$bodyBg: #040507;
$pending: #FF9720;
$reject: #F55A5A;
$rejectBorder: rgba($reject,0.22);
$rejectFieldBg: rgba($reject,0.12);
$rejectBg: rgba($reject,0.28);
$success: #28A745;
$successStatus: #09C74A;
$scrollTrack: rgba($fieldColor,0.16);
$fieldBg: rgba(#D0D9E7,0.88);
$coverLayer: #171719;
$blurBg: rgba($white,0.75);
$navMobileBg: rgba(#8e8e93,0.09);
$bottomMenuColor: #8e8e93;
$animateStart: $timeBg;
$animateEnd: rgba($lightBg,0.5);
$gamesNavBg: #040507;
$gamesNavBg00: rgba($gamesNavBg,0);
$historyBg: rgba(#D0D9E7,0.12);
$tableBorder: rgba(#bebebe,0.5);
$description: #9C9C9E;
$statusTrack: #dddddd;
$pending01: rgba($pending,0.1);
$success01: rgba($success,0.1);
$reject01: rgba($reject,0.1);
$disableColor: #474E63;
$disableBg: #1F2330;
$disableProfile: #616161;
$placeholder: #778090;
$shadowColor: rgba($hoverColor,0.75);
$siteColorShadow: rgba($siteColor, 0.3);
$submenuColor: #8B8C91;
$infoTextColor: #c8c8c8;
$dropdownBg: #121520;
$seeMoreBorder: #353849;
$depositColor: #6C757D;

// tournament

$btnColor: #B8050B;
$gold: #FFE485;
$bgCard: #1B1B1B;

//second theme colors, when body has "dark_theme" className
$darkSiteColor: #7BF720;
$darkSiteBg: #000000;

//shadow variables
$shadowLighter: 0 1px 1px rgba($black, 0.1); //#0000001A
$shadowLight: 0 2px 2px rgba($black, 0.1);
$shadowLightRw: 0 -1px 2px rgba($black, 0.1);
$shadowMiddle: 0 2px 2px rgba($black, 0.13); //#00000021
$shadowDark: 0 3px 6px rgba($black, 0.16); //#00000029

//transitions
$sidebarTrs: 0.5s linear;

//gradients
$pinkGradient: linear-gradient(to top right, #FFA3AC,#FF75B8);
$lBlueGradient: linear-gradient(to top right, #66A7FF,#8B7DFF);
$lBlueGradientRw: linear-gradient(to left bottom, #66A7FF,#8B7DFF);
$greenGradient: linear-gradient(to top right, #2FB9C1,#91E698);
$blueGradient: linear-gradient(to top right, #37AEE2,#1E94D3);
$difGradient: linear-gradient(to top right, #6A82FB 45%,#FC5C7D);
$gameBtnGradient: linear-gradient(to top right, #006CFF, #0267F0);
$gamesNavEndGradient: linear-gradient(to right, var(--gamesNavBg00), var(--gamesNavBg));
$gamesNavStartGradient: linear-gradient(to left, var(--gamesNavBg00), var(--gamesNavBg));
$statusGradient: repeating-linear-gradient(45deg, #56c756 0 $colSpaceMd, #72cf73 $colSpaceMd 2*$colSpaceMd);
$gamblingOutRed: $siteColor;
$gamblingOutYellow: #FDAE38;
$textColorGray: #91908E;

//modal
$modalBorderRedColor: #F9472F;
$modalCancelButtonColor: #C4BDAF;
$textColorGray: #91908E;
$indicatorRed: #F9472F;
$inboxActiveItemColor: #282C3D;

