@import '../../../default-scss/variables';
@import '../../../default-scss/mixins';
@import '../../../default-scss/extendsStart';

.affiliate_business {
    position: relative;
    height: 125px;
    border-style: solid;
    border-width: 0 $colSpace;
    border-color: transparent;
    .button_block {
        width: 50%;
        min-width: 50px;
        padding: $sideSpaceSm $colSpaceMd 0;
        position: absolute;
    }
    .affiliate_block {
        top: 0;
        left: 0;
    }
    .business_block {
        bottom: $sideSpaceLg;
        right: 0;
    }
    a {
        display: block;
        @include overHidden();
        border-radius: $colSpaceMd;
        box-shadow: $shadowMiddle;
        z-index: 2;
        &:before {
            display: block;
            content: "";
            padding-bottom: 100%;
        }
        @include buttonAfter($colSpace);
        @extend %btnHover;
        &:after {
            @include background($siteColor,--siteColor);
        }
        .item_inner {
            @extend %coverLayer;
            display: flex;
            @include color($siteColor,--siteColor);
            padding: $colSpaceMd;
            justify-content: center;
            align-items: center;
            font-family: $bold;
            @include animStyle(font-size 0.25s linear);
            img {
                max-width: 100%;
                max-height: 100%;
            }
            line-height: 30px;
            font-size: 120%;
            @include animDelay(0s);
        }
    }
    @media #{$until1439} and #{$since1200} {
        .button_block a .item_inner {
            font-size: 120%;
            @include animDelay(0s);
        }
    }
}


@import '../../../default-scss/extendsEnd';