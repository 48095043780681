@mixin standardList() {
    padding: 0;
	margin: 0;
	list-style-type: none;
}
@mixin slider() {
	position: relative;
	white-space: nowrap;
	font-size: 0;
	overflow: hidden;
}
@mixin slide() {
	display: inline-block;
	white-space: normal;
	font-size: 1rem;
}
@mixin centerCover() {
	background-size: cover !important;
	background-position: center !important;
}
@mixin animStyle($parameters) {
  	-o-transition: $parameters;
	-ms-transition: $parameters;
	-moz-transition: $parameters;
	-webkit-transition: $parameters;
	transition: $parameters;
}
@mixin animStyles($param1,$param2) {
	-o-transition: $param1,$param2;
  -ms-transition: $param1,$param2;
  -moz-transition: $param1,$param2;
  -webkit-transition: $param1,$param2;
  transition: $param1,$param2;
}
@mixin transStyle($parameters) {
	transform: $parameters;
	-moz-transform: $parameters;
    -ms-transform: $parameters;
    -webkit-transform: $parameters;
    -o-transform: $parameters;
}
@mixin torigin($parameters) {
	transform-origin: $parameters;
	-webkit-transfrom-origin: $parameters;
}
@mixin animDelay($parameters) {
	-o-transition-delay: $parameters;
	-ms-transition-delay: $parameters;
	-moz-transition-delay: $parameters;
	-webkit-transition-delay: $parameters;
	transition-delay: $parameters;
}
@mixin dropArrow() {
	color: inherit;
	border-style: solid;
	border-left-color: transparent;
	border-right-color: transparent;
}
@mixin overHidden() {
	position: relative;
	overflow: hidden;
}
@mixin psevdoAbs() {
	display: block;
	content: "";
	position: absolute;
}
@mixin maskedInput() {
	position: absolute;
	width: 0;
	height: 0;
	top: 0;
	left: 0;
	visibility: hidden;
}
@mixin colCount($col) {
	-moz-column-count: $col;
    -webkit-column-count: $col;
    column-count: $col;
}
@mixin colGap($gap) {
    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}
@mixin rowList($space) {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$space;
	margin-right: - $space;
	> * {
		padding-left: $space;
		padding-right: $space;
	}
}



@mixin customScroll($width, $height, $mozWidth, $trackRadius, $barRadius, $trackBg, $barBg) {
	scrollbar-color: $barBg $trackBg;
	scrollbar-width: $mozWidth;
	&::-webkit-scrollbar {
		width: $width;
		height: $height;
		border-radius: $trackRadius;
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $barRadius;
		background: transparent;
	}
	&:hover {
		&::-webkit-scrollbar {
			background:  $trackBg;
		}
		&::-webkit-scrollbar-thumb {
			background: $barBg;
		}
	}
}

@mixin newcustomScroll($size, $mozWidth, $trackRadius, $barRadius, $trackBg, $barBg) {
    scrollbar-color: $barBg $trackBg;
    scrollbar-width: $mozWidth;
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
        border-radius: $trackRadius;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: $barRadius;
        background: $barBg;
    }
    &::-webkit-scrollbar-track {
        border-radius: $trackRadius;
        background: $trackBg;
    }
}

@mixin color($color1,$color2) {
	color: $color1;
	color: var($color2);
}

@mixin background($bg1,$bg2) {
	background-color: $bg1;
	background-color: var($bg2);
}

@mixin border($border1,$border2) {
	border-color: $border1;
	border-color: var($border2);
}

@mixin shadow($size, $color1, $color2) {
	box-shadow: $size $color1;
	box-shadow: $size $color2;
}

@mixin modal($maxSize) {
	@include background($lightBg,--lightBg);
	max-width: $maxSize;
	border-radius: 9px;
	margin: 0 auto;
	width: 90vw;
	z-index: 3;
	word-break: break-word;
}

@mixin gradientColor($gradient) {
	background: $gradient;
	background-clip: text;
  	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
@mixin filter($params) {

	-o-filter: $params;
	-ms-filter: $params;
	-moz-filter: $params;
	-webkit-filter: $params;
	filter: $params;
}
@mixin backFilter($params) {
	-o-backdrop-filter: $params;
	-ms-backdrop-filter: $params;
	-moz-backdrop-filter: $params;
	-webkit-backdrop-filter: $params;
	backdrop-filter: $params;
}
@mixin buttonAfter($radius) {
	&:after {
		position: absolute;
		display: block;
		content: "";
		left: 50%;
		top: 50%;
		width: 0;
		padding-bottom: 0;
		opacity: 0;
		min-height: 0;
		border-radius: $radius;
		@include transStyle(translate3d(-50%,-50%,0));
		@include animStyles(all 1s, opacity 0.15s);
	}

}
@mixin hoverAfter() {
	&:hover:after {
		@include animStyles(all 0.3s, opacity 0.01s);
		opacity: 0.2;
		width: 120%;
		padding-bottom: 100%;
		min-height: 100%;
	}
}
@mixin touchAfter() {
	&:active:after {
		@include animStyles(all 0.05s, opacity 0.001s);
		opacity: 0.2;
		width: 120%;
		padding-bottom: 100%;
		min-height: 100%;
	}
}

@mixin linkAfter($size) {
	&:after {
		@include psevdoAbs();
		width: 0;
		bottom: -$size + 1;
		content: "";
		left: 0;
		right: 0;
		margin: auto;
		border-top: $size solid;
		@include animStyle(width 0.3s);
	}
}
@mixin ltrAfter($color1,$color2,$opacity) {
	&:after {
		@include psevdoAbs();
		top: 0;
		left: 0;
		height: 100%;
		width: 0;
		@include background($color1,$color2);
		opacity: $opacity;
		@include animStyle(width 0.3s);
	  }
}
@mixin linkHover() {
	&:hover:after {
		width: 100%;
	}
}
@mixin linkTouch() {
	&:active:after {
		width: 100%;
		@include animStyle(width 0.15s);
	}
}

@mixin showAnimation() {
	opacity: 0;
	animation-name: slowShowing;
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}
@mixin textOverflow($rowCount,$position) {
	position: $position;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $rowCount;
	-webkit-box-orient: vertical;
}

@mixin siteColorHover {
	@extend %siteColorHover;
	@extend %siteColorActive;
}

@mixin siteBgHover {
	@extend %siteBgHover !optional;
	@extend %siteBgActive;
}

@mixin siteBgHover2 {
	@extend %siteBgHover2;
	@extend %siteBgActive2;
}

@mixin lightBgHover {
	@extend %lightBgHover;
	@extend %lightBgActive;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
