@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

.internet_error {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 30px;
  background: $reject;
  margin-top: 0;
  font-size: $sideSpaceLg;
  transition: height 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  p {
    font-size: $sideSpaceLg;
    line-height: 30px;
    color: $textColor;
    margin: 0;
  }
}


@import '../../default-scss/extendsEnd';


