@import '../../../default-scss/variables';
@import '../../../default-scss/mixins';
@import '../../../default-scss/extendsStart';

.main_menu {
  width: 100%;
  @include overHidden();
  margin-bottom: auto;
  > ul {
    @extend %standardList;
    width: $sideBarInnerWidth;
    li:before {
      @include border($detailsBorder,--detailsBorder);
      display: block;
      margin: 0 $sideSpaceLg;
      content: "";
    }
  }
  .menu_item {
    display: flex;
    align-items: center;
    font-size: 140%;
    line-height: 18px;
    color: inherit;
    padding: 7px $sideSpaceLg;
    position: relative;
    &:before {
      width: 26px;
      min-width: 26px;
      @include color($textColor,--textColor);
      font-size: 1.6rem;
      display: block;
    }
    @include ltrAfter($lightColor,--lightColor,0.1);
    @extend %linkHover;
    &.current {
      &:before {
        @include color($siteColor,--siteColor);
      }
    }
  }
  @media #{$until1199} {
    padding-top: $sideSpaceLg;
    ul {
      width: 100%;
    }
    li:first-child:before {
      display: none;
    }
    .menu_item {
      height: 40px;
      font-size: 140%;
      line-height: 19px;
      &:before {
        width: 28px;
        min-width: 28px;
        margin-right: $sideSpaceSm;
      }
    }
  }
}

@import '../../../default-scss/extendsEnd';