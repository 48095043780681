@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

.user_info {
  display: flex;
  align-items: center;
  flex: 1;
  width: 50%;
  color: inherit;
  .name_balance {
    flex: 1;
    width: 50%;
    // text-align: right;
  }
  .user_name {
    display: block;
    max-width: 100%;
    @include overHidden();
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .user_balance {
    display: block;
    font-family: $bold;
    white-space: nowrap;
  }
}
.user_info_wrapper{
  display: flex;
  align-items: flex-end;
}

.user_balance_dropBtn:before {
  order: 1;
  padding-left: 6px;
  display: block;
  font-size: 30%;
  color: var(--siteColor);
  transition: all 0.3s;
  margin: 2px auto 0;
  cursor: pointer;
}
.user_balance_dropBtn_rotate:before {
  @extend %horizontalRotate;
  transition: all 0.3s;
}
.user_image {
  display: block;
  @include overHidden();
  border-radius: 50%;
  img {
    @include overHidden();
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.profile_block {
  > .deposit_btn {
    @extend %button;
    @extend %mainButton;
    &:before {
      margin-right: 6px;
      font-size: 150%;
    }
  }
  .user_name {
    font-size: 120%;
    line-height: 17px;
  }
  .user_balance {
    font-size: 150%;
    line-height: 19px;
    float: left;
    display: flex;
    align-items: center;
    @include color($siteColor,--siteColor);
  }

  .currencyIconWrapper {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    color: #000;
    font-size: 75%;
    // background-color: #fff;
    @include background($siteColor,--siteColor);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .balanceDropDownWrapper {
    top: 100%;
    position: absolute;
    height: 134px;
    width: 228px;
    border-radius: 5px;
    background-color: #000;
    border: 0.3px white solid;
    padding: 16px 20px 20px 20px;
    right: 16px;
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .totalBalanceWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .sports_book_btn {
    &.active {
      background: $siteColorHover!important;
      color: $textColor!important;
      pointer-events: none;
    }
  }
}
.user_block {
  display: flex;
  align-items: center;
  .deposit_btn {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    @include background($siteColor,--siteColor);
    font-size: 100%;
    box-shadow: $shadowDark;
    @include overHidden();
    @include siteBgHover2;
    z-index: 2;

  }
}

.header {
  .profile_block {
    display: flex;
    align-items: center;
    margin-left: $sideSpaceSm;
    flex-direction: row-reverse;
    > .deposit_btn {
      @extend %smallButton;
      min-width: 97px;
      position: relative;
      overflow: hidden;

    }
  }
  .user_block {
    margin-left: $sideSpace;
    // @include overHidden();
    display: flex;
    justify-content: space-between;

    padding: $colSpaceSm;
    border-radius: 22px;
    max-width: 220px;
    background-image: none !important;

    .name_balance {
      max-width: 115px;
    }

    .deposit_btn {
      margin-left: $colSpace;
    }
    @include lightBgHover;
    &:after {
      @include background($fieldBg,--fieldBg);
      pointer-events: none;
    }
  }
  .user_image {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: $colSpace;
  }
  .buttons_block {
    display: none;
  }
}

.balanceDropDownWrapper {
  font-size: 150%;

    .balanceDropHeader {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      margin: 0 auto;
      color: #EAE7D6;
      .totalBalanceWrapper {
        font-family: "source_sans_bold";
        .currencyIconWrapper {
          background-color: #EAE7D6;
        }
      }
    }
    .balanceDropBody {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 12px auto 0;
      .balancePrice {
        @include color($siteColor,--siteColor);
        font-family: source_sans_bold;
      }
      .balancePlusIcon {
        margin: 0 11px;
        font-size: 150%;
        @include color($siteColor,--siteColor);
      }
      .bonusBalanceWrapper {
        text-align: center;
        .totalBalanceWrapper {
          color: #EAE7D6;
          margin: 4px 0;
          font-size: 12px;
        }
        .icon_user_bonus_balance:before {
          font-size: 160%;
          @include color($siteColor,--siteColor);
        }
        .currencyIconWrapper {
          @include color($siteColor,--siteColor);
        }
      }
      .realBalanceWrapper {
        text-align: center;

        .totalBalanceWrapper {
          margin: 4px 0;
          color: #EAE7D6;
          font-size: 12px;
        }
        .icon_user_balance_case {
          position: relative;
          width: 24px;
          height: 24px;
          margin: 0 auto;

          span {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #000;
            font-weight: 600;
          }
        }

        .icon_user_balance_case:before {
          font-size: 160%;
          @include color($siteColor,--siteColor);
        }
        .icon_user_bonus_balance:before {
          font-size: 160%;
          @include color($siteColor,--siteColor);
        }
        .currencyIconWrapper {
          @include color($siteColor,--siteColor);
        }
      }
    }
}

@media #{$until1439} {
  .header .user_block {
    margin-left: $sideSpaceSm;
  }
}
@media #{$until1199} {
  .header {
    .profile_block {
      margin-left: auto;
      margin-right: -$colSpaceSm;
    }
    .user_image {
      width: 28px;
      height: 28px;
      min-width: 28px;
      margin-right: $colSpace;
    }
    .user_block {
      .deposit_btn {
        width: 30px;
        min-width: 30px;
        height: 30px;
        margin-left: $colSpaceSm;
      }
    }
    .user_name {
      line-height: 15px;
    }
    .user_balance {
      line-height: 17px;
    }
  }
}
@media #{$until575} {
  .header .user_block {
    max-width: 150px;

    .name_balance {
      max-width: 60px;
    }
  }
  .user_info_wrapper{
    margin-right: 4px;
  }
}
@media #{$until413} {
  .header {
    .user_image {
      display: none;
    }
    .user_block {
      margin-left: 0;
      padding-left: $sideSpaceLg;
      max-width: 130px;
    }
    .user_info .name_balance {
      text-align: left;
    }
  }
}
@media #{$until359} {
  .header {
    .user_block {
      max-width: 120px;
    }
  }
}

.active_nav_link {
  background: $siteColor !important;
  color: $black !important;
}

@import '../../default-scss/extendsEnd';
