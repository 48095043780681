@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

@keyframes smallAnimate {
  0% {
    background-position: -300px 0
  }
  100% {
    background-position: 1100px 0
  }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: smallAnimate;
  animation-timing-function: linear;
  background: linear-gradient(to right, $animateStart 8%, $animateEnd 18%, $animateStart 33%);
  background: linear-gradient(to right, var(--animateStart) 8%, var(--animateEnd) 18%, var(--animateStart) 33%);
  background-size: 1400px 104px;
  position: relative;
}

.tournamentConditions {
  margin: 0 -#{$colSpaceMd};
  padding: 0;
  list-style-type: none;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  height: 240px;

  li {
    flex: 1;
    min-width: 80 + 2*$colSpaceMd;
    padding: $sideSpaceSm $colSpaceMd;

    &:before {
      display: block;
      width: 100%;
      content: "";
      border-radius: 2+$sideSpaceSm;
      @include background($lightBg, --lightBg);
      @extend .animated-background;
      min-height: 160px;
      margin-right: 80px;
    }
  }
}

.category_preloader {
  //margin: 0 -#{$colSpaceMd};
  padding: 0;
  list-style-type: none;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  height: 80 + 2*$sideSpaceSm;

  li {
    flex: 1;
    min-width: 80 + 2*$colSpaceMd;
    padding: $sideSpaceSm $colSpaceMd;

    &:before {
      display: block;
      width: 100%;
      content: "";
      border-radius: 2+$sideSpaceSm;
      @include background($lightBg, --lightBg);
      @extend .animated-background;
      height: 80px;
    }
  }
}

.tournamentBanner {
  margin: 0 auto 80px;
  padding: 0;

  li {
    flex: 1;
    min-width: 80 + 2*$colSpaceMd;
    padding: $sideSpaceSm $colSpaceMd;
    list-style: none;

    &:before {
      display: block;
      width: 100%;
      content: "";
      border-radius: 2+$sideSpaceSm;
      @include background($lightBg, --lightBg);
      @extend .animated-background;
      height: 750px;
    }
  }
}

.tournamentPrize {
  margin: 0 auto 80px;
  padding: 0;

  li {
    flex: 1;
    min-width: 80 + 2*$colSpaceMd;
    padding: $sideSpaceSm $colSpaceMd;
    list-style: none;

    &:before {
      display: block;
      width: 650px;
      content: "";
      border-radius: 2+$sideSpaceSm;
      @include background($lightBg, --lightBg);
      @extend .animated-background;
      height: 650px;
      margin-right: 20px;
    }
  }
}

.games_page .navigation_block .custom_container > .preloader {
  flex: 1;
  width: 40%;

  .category_preloader {

    height: 40px;
    margin: 0 -#{$colSpaceMd /2} $colSpace;

    li {
      min-width: 120 + $colSpaceMd;
      padding: 0 $colSpaceMd /2;

      &:before {
        height: 40px;
      }

      @media #{$until413} {
        min-width: 33.3%;
      }
    }
  }
}

.provider_preloader {
  max-width: $containerWidth + 2* $sideSpaceLg;
  display: flex;
  padding: 0 2*$sideSpaceLg;
  list-style-type: none;
  margin: 20px -40px 0;
  height: 32px + 2*$colSpaceMd;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: flex-start;
  @media #{$until575} {
    margin: 0 -60px;

  }

  li {
    flex: 1;
    min-width: 90px;
    padding: $colSpaceMd $colSpaceSm;

    &:before {
      height: 32px;
      display: block;
      content: "";
      border-radius: 2+$sideSpaceSm;
      @include background($lightBg, --lightBg);
      @extend .animated-background;
    }

    @media #{$until767} {
      min-width: 90px;
    }
  }
}

.list_head_tournament .animate_block {
  display: block;
  width: 350px;
  max-width: 100%;
  height: 32px;
  margin-bottom: 40px;
  border-radius: 2+$sideSpaceSm;
  @include background($lightBg, --lightBg);
  @extend .animated-background;
  @media #{$until1199} {
    height: 28px;
  }
  @media #{$until767} {
    height: 24px;
  }
}

.list_head .animate_block {
  display: block;
  width: 350px;
  max-width: 100%;
  height: 32px;
  margin-bottom: 40px;
  @include background($lightBg, --lightBg);
  @extend .animated-background;
  @media #{$until1199} {
    height: 28px;
  }
  @media #{$until767} {
    height: 24px;
  }
}

.main_wrapper .main_banner {
  width: 100%;
  max-width: 1900px;
  padding: 0 24px;
  margin: 0 auto;
  display: block;
  height: 391px;
  margin-bottom: 40px;
  border-radius: 8px;
  list-style: none;
  @include background($lightBg, --lightBg);
  @extend .animated-background;

  span {
    display: none;
  }

  @media #{$until1199} {
    height: 300px;

  }
  @media #{$until767} {
    height: 200px;

  }
}

.game_title_preloader {
  margin-top: 33px;
  border-radius: 32px;
}

.games_preloader {
  margin: 0 -#{$colSpace};
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 0 16.66%;
    max-width: 16.66%;
    padding: 0 $colSpace 2*$sideSpaceLg + 60;

    span {
      position: relative;
      display: block;
      padding-bottom: 100%;
      border-radius: 32px;
      @extend .animated-background;

      &:before,
      &:after {
        @include psevdoAbs;
        left: $sideSpaceLg;
        height: 18px;

        @extend .animated-background;
        top: 100%;
        width: auto;
      }

      &:before {
        margin-top: $sideSpaceSm + 2;
        right: $sideSpaceLg;
      }

      &:after {
        margin-top: $sideSpaceSm + 26;
        right: 2*$sideSpaceLg;
      }
    }

    @media #{$until1199} {
      padding-bottom: 2*$sideSpaceLg + 48;
      span {
        border-radius: $sideSpace;

        &:before {
          height: 14px;
          margin-top: $colSpaceMd + 2;
          left: $sideSpaceSm;
          right: $sideSpaceSm;
        }

        &:after {
          height: 14px;
          margin-top: $colSpaceMd + $sideSpaceLg + 2;
          left: $sideSpaceSm;
        }
      }
    }
    @media #{$until991} {
      flex: 0 0 20%;
      max-width: 20%;
      &:nth-child(n+16) {
        display: none;
      }
    }
    @media #{$until767} {
      flex: 0 0 25%;
      max-width: 25%;
      &:nth-child(n+13) {
        display: none;
      }
    }
    @media #{$until639} {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      &:nth-child(n+10) {
        display: none;
      }
    }
    @media #{$until479} {
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      padding-bottom: 62 + 2*$sideSpaceLg;
      &:nth-child(n+7) {
        display: none;
      }
      &:before {
        @include psevdoAbs();
        @include background($lightBg, --lightBg);
        border-radius: 0 0 $sideSpaceSm + 2 $sideSpaceSm + 2;
        left: $colSpace;
        right: $colSpace;
        bottom: 2*$sideSpaceLg;
        height: 62px;
      }
      span {
        z-index: 2;
        border-radius: $sideSpaceSm + 2 $sideSpaceSm + 2 0 0;

        &:before {
          height: 16px;
        }

        &:after {
          height: 16px;
          margin-top: $colSpaceSm + 24;
        }
      }
    }
    @media #{$until413} {
      padding-bottom: 58 + 2*$sideSpaceLg;
      &:before {
        height: 58px;
      }
      span {
        &:before {
          height: 14px;
        }

        &:after {
          height: 14px;
          margin-top: $colSpaceSm + 22;
        }
      }
    }
  }
}

.start_game_preloader {
  margin: 0 -#{$colSpace};
  padding: 0;
  height: 749px;
  @extend .animated-background;

  @media #{$until1199} {
    height: 674px;
  }
}


.games_group .games_preloader {
  @media #{$until1199} {
    position: relative;
    overflow: hidden;
    padding-bottom: 5px;
    margin-bottom: -5px;
    flex-direction: column;
    align-content: flex-start;
    height: 210px;
    margin-left: -$sideSpace;
    margin-right: -$sideSpace;
    padding-left: $sideSpace -$colSpace;
    li {
      padding-bottom: 0;
      flex: none;
      min-width: 33.3%;
      position: relative;
      display: flex;
      align-items: center;

      &:nth-child(odd) {
        &:before {
          @include psevdoAbs();
          top: 0;
          left: $colSpace;
          right: $colSpace;
          height: 200%;
          box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
          border-radius: 14px;
          @include background($lightBg, --lightBg);
        }

        &:after {
          @include psevdoAbs();
          left: $sideSpaceLg;
          right: $sideSpaceLg;
          bottom: 0;
          height: 1px;
          background-color: rgba(142, 142, 147, .15);
          background-color: var(--detailsBorder);
        }
      }

      span {
        width: 66px;
        height: 66px;
        padding-bottom: 0;
        border-radius: 14px;
        margin: $sideSpaceMd;

        &:before {
          margin-top: -16px;
          left: 100%;
          top: 50%;
          margin-left: $sideSpaceMd;
          right: auto;
          width: 180px;
          height: 14px;
        }

        &:after {
          margin-top: 2px;
          left: 100%;
          top: 50%;
          margin-left: $sideSpaceMd;
          right: auto;
          width: 200%;
          height: 14px;
        }
      }
    }
  }
  @media screen and (max-width: 1139px) and (min-width: 992px) {
    li span {
      width: 60px;
      height: 60px;
      border-radius: $sideSpaceSm;

      &:before {
        margin-left: $colSpace;
        height: 12px;
        margin-top: -14px;
      }

      &:after {
        margin-left: $colSpace;
        height: 12px;
      }
    }
  }
  @media #{$until991} {
    li {
      flex: none;
      max-width: none;
      width: 47%;
      min-width: 360px;
    }
  }
  @media #{$until413} {
    margin-left: -$sideSpaceMd;
    margin-right: -$sideSpaceMd;
    padding-left: $colSpaceMd - 1;
    padding-right: $sideSpaceMd;
    li {
      width: 95%;
      min-width: 0;

      span {
        width: 60px;
        height: 60px;
        border-radius: $sideSpaceSm;

        &:before {
          margin-left: $colSpace;
          height: 12px;
          margin-top: -14px;
        }

        &:after {
          margin-left: $colSpace;
          height: 12px;
        }
      }
    }
  }
  @media #{$until359} {
    li {
      width: 95%;
      min-width: 0;

      span {
        width: 48px;
        height: 48px;
        border-radius: $colSpace;
        margin: $sideSpaceSm;

        &:before {
          height: 10px;
          margin-top: -12px;
          width: 160px;
        }

        &:after {
          height: 11px;
        }
      }
    }
  }
}

.profile_preloader {
  height: 100%;
  margin-top: 150px;

  .top_preloader {
    @include standardList;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 30px;
    padding: 0;

    li {
      padding: 0 10px;
      flex: 0 0 80%;
      max-width: 80%;

      span {
        height: 180px;
        border-radius: $colSpaceMd;
        @include background($lightBg, --lightBg);
        @extend .animated-background;
        margin-bottom: $sideSpaceSm;
        width: 100%;
        box-shadow: $shadowLight;
        display: block;
      }

      &:last-child {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }

  .bottom_preloader {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    > ul {
      @include standardList;
      padding: 0 10px;
      flex: 0 0 20%;
      max-width: 20%;
      display: flex;
      flex-direction: column;

      > li {
        width: 100%;
        flex: 1;
        margin-bottom: 20px;

        span {
          height: 60px;
          border-radius: $colSpaceMd;
          @include background($lightBg, --lightBg);
          @extend .animated-background;
          margin-bottom: $sideSpaceSm;
          width: 100%;
          box-shadow: $shadowLight;
          display: block;
        }

      }

      &:last-child {
        padding: 0 10px;
        flex: 0 0 80%;
        max-width: 80%;

        li {
          flex: unset;
        }
      }

    }

  }
}

.help_page .profile_preloader li {
  height: 70px;
  @media #{$until767} {
    height: 62px;
  }
}

.inbox_preloader {
    margin: 0;
    list-style-type: none;
    border-radius: $colSpaceMd;
    @include overHidden();
    padding-inline-start: 0;

    li {
        @include background($timeBg,--timeBg);
        position: relative;
        padding: 8px 16px;
        margin-bottom: 20px;
        border-radius: 10px;

        &:not(:first-child) {
            &:before {
                @include psevdoAbs();
                top: 0;
                left: $sideSpace;
                right: $sideSpace;
                height: 1px;
                @include background($detailsBorder,--detailsBorder);
            }
        }
        span {
            position: relative;
            display: block;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            @extend .animated-background;
            &:before,
            &:after {
                @include psevdoAbs();
                left: 100%;
                @extend .animated-background;
                top: 50%;
                height: 15px;
            }
            &:before {
                margin-left: $sideSpace - 4;
                width: 150px;
                margin-top: -17px;
            }
            &:after {
                margin-left: $sideSpace;
                width: 250px;
                margin-top: 2px;
            }
        }
    }
    @media #{$until1199} {
        &:before {
            left: $sideSpaceLg;
        }
        li {
            padding: $sideSpaceSm $sideSpaceLg;
            &:not(:first-child):before {
                left: $sideSpaceLg;
                right: $sideSpaceLg;
            }
            span:before {
                margin-left: $sideSpace;
            }
            span:after {
                margin-left: $sideSpaceMd;
            }
        }
    }
    @media #{$until767} {
        &:before {
            height: 16px;
            top: $sideSpace;
        }
        li span {
            &:before {
                margin-left: $colSpaceMd;
                height: 13px;
                margin-top: -15px;
            }
            &:after {
                margin-left: $sideSpaceSm;
                height: 13px;
            }
        }
    }
    @media #{$until413} {
        &:before {
            left: $sideSpaceSm;
        }
        li {
            padding: $sideSpaceSm;
            &:not(:first-child):before {
                left: $sideSpaceSm;
                right: $sideSpaceSm;
            }
            span {
                width: 32px;
                height: 32px;
                &:before {
                    margin-left: $colSpaceMd /2;
                    height: 12px;
                    margin-top: -14px;
                    width: 120px;
                }
                &:after {
                    margin-left: $colSpace;
                    height: 12px;
                    width: 180px;
                }
            }
        }
    }
}

.table_preloader {
  margin: 0;
  padding: 0 $sideSpace $sideSpace;
  list-style-type: none;

  li {
    height: 32px;
    display: flex;
    position: relative;
    align-items: center;
    @include background($historyBg, --historyBg);
    opacity: 0.3;

    &:nth-child(2n+1) {
      @include background($timeBg, --timeBg);
      opacity: 0.7;
    }

    @extend .animated-background;
  }

  @media #{$until1199} {
    padding: 0 $sideSpaceLg $sideSpace;
  }
  @media #{$until575} {
    padding: 0 $sideSpaceMd $sideSpace;
    li {
      height: 50px;
      border-bottom: 1px solid;
      @include border($animateEnd, --animateEnd);
    }
  }
}

.history_popup .table_preloader {
  padding: 0 0 $sideSpaceLg;

  li {
    height: 50px;
    border-bottom: 1px solid;
    @include border($animateEnd, --animateEnd);
  }
}

.profile_page .container_inner > .preloader {
  flex: 1;
  padding: $sideSpaceSm $colSpaceSm;
}

.info_prelodaer {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  margin: 0 auto;
  max-width: 724px;
  padding: 24px 24px 50px;
  width: 100%;

  li {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
    margin-bottom: 20px;
    @include overHidden;

    &:nth-child(2n + 1) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &:before {
      display: block;
      content: "";
      @extend .animated-background;
      border-radius: $sideSpace + 4;
      box-shadow: $shadowDark;
      height: 26px;
    }

  }
}

.promotion_preloader {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  li {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 0 15px;
    margin-bottom: 20px;
    @include overHidden;

    &:first-child {
      flex: 0 0 100%;
      max-width: 100%;

      &:before {
        height: 440px;
      }
    }

    &:before {
      display: block;
      content: "";
      @extend .animated-background;
      border-radius: $sideSpace + 4;
      box-shadow: $shadowDark;
      height: 340px;
    }

    @media #{$until1439} {
      &:before {
        height: 250px;
      }
      &:first-child:before {
        height: 420px;
      }
    }
    @media #{$until1199} {
      flex: 0 0 50%;
      max-width: 50%;
      &:nth-child(n+4) {
        display: none;
      }
    }
    @media #{$until767} {
      flex: 0 0 100%;
      max-width: 100%;
      &:before {
        height: 325px !important;
      }
    }
    @media #{$until413} {
      &:before {
        height: 280px !important;
      }
    }
    @media #{$until359} {
      &:before {
        height: 240px !important;
      }
    }
  }
}

.promotion_inner_preloader {
  margin: 0 -24px;
  .promotion_inner_banner {
    width: 100%;
    height: 530px;
    max-height: 530px;
    min-height: 360px;
    @extend .animated-background;

    @media #{$until1279} {
      height: 360px;
    }
  }
}

.payments_preloader {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 0;

  li {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 0 30px;
    margin-bottom: 20px;
    @include overHidden;

    &:before {
      display: block;
      content: "";
      @extend .animated-background;
      border-radius: $sideSpace + 4;
      box-shadow: $shadowDark;
      height: 103px;
    }

    @media #{$until1199} {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    @media #{$until767} {
      &:before {
        height: 80px !important;
      }
    }
    @media #{$until575} {
      flex: 0 0 50%;
      max-width: 100%;
      &:before {
        height: 80px !important;
      }
    }
  }
}

.profile_details_preloader {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 15px 0 0;

  li {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 0 30px;
    margin-bottom: 20px;
    @include overHidden;

    &:before {
      display: block;
      content: "";
      @extend .animated-background;
      border-radius: $sideSpace + 4;
      box-shadow: $shadowDark;
      height: 103px;
    }

    @media #{$until1199} {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    @media #{$until767} {
      &:before {
        height: 80px !important;
      }
    }
    @media #{$until575} {
      flex: 0 0 50%;
      max-width: 100%;
      &:before {
        height: 80px !important;
      }
    }
  }
}

.search_preloader {
  width: 200px;
  height: 32px;
}

.results_preloader {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    padding: $colSpace $sideSpaceLg;

    &:not(:first-child) {
      border-top: 1px solid;
      @include border($detailsBorder, --detailsBorder);
    }

    span {
      position: relative;
      display: block;
      width: 66px;
      height: 66px;
      border-radius: $sideSpaceSm;
      @extend .animated-background;

      &:before,
      &:after {
        @include psevdoAbs();
        left: 100%;
        margin-left: $sideSpaceSm;
        height: 12px;
        @extend .animated-background;
      }

      &:before {
        top: $colSpaceMd;
        width: 160px;
      }

      &:after {
        top: $sideSpace;
        width: 120px;
      }

      @media #{$until1199} {
        &:before {
          margin-left: $sideSpaceMd;
          height: 16px;
          top: $colSpace + 1;
        }
        &:after {
          margin-left: $sideSpaceMd;
          height: 16px;
          top: $sideSpace + $colSpaceSm;
        }
      }
      @media #{$until413} {
        &:before {
          margin-left: $sideSpaceSm;
          height: 14px;
          top: $colSpaceMd;
        }
        &:after {
          margin-left: $sideSpaceSm;
          height: 14px;
          top: $sideSpace + 2;
        }
      }
      @media #{$until359} {
        width: 48px;
        height: 48px;
        border-radius: $colSpace;
        &:before {
          margin-left: $colSpace;
          height: 10px;
          top: 2px;
          width: 100px;
        }
        &:after {
          margin-left: $colSpace;
          height: 10px;
          top: $sideSpaceMd;
          width: 80px;
        }
      }

    }
  }
}

.mob_list_preloader {
  margin: 0 #{-$colSpace};
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 0 33.3%;
    max-width: 33.3%;
    padding: 0 $colSpace $sideSpaceLg;
    position: relative;

    span {
      position: relative;
      width: 66px;
      height: 66px;
      border-radius: $sideSpaceSm + 2;
      margin: $sideSpaceMd;
      @extend .animated-background;
      z-index: 2;
      display: block;

      &:before,
      &:after {
        @include psevdoAbs();
        margin-left: $sideSpaceMd;
        top: 50%;
        height: 16px;
        @extend .animated-background;
        left: 100%;
      }

      &:before {
        width: 180px;
        margin-top: -20px;
      }

      &:after {
        width: 140px;
        margin-top: 2px;
      }
    }

    &:before {
      @include psevdoAbs();
      left: $colSpace;
      right: $colSpace;
      top: 0;
      bottom: $sideSpaceLg;
      @include background($lightBg, --lightBg);
      border-radius: $sideSpaceSm + 2;
      box-shadow: $shadowLight;
    }

    @media screen and (max-width: 1139px) and (max-width: 1199px) and (min-width: 960px) {
      span {
        width: 60px;
        height: 60px;

        &:before {
          margin-left: $colSpace;
          width: 160px;
          height: 14px;
          margin-top: -18px;
        }

        &:after {
          margin-left: $colSpace;
          width: 120px;
          height: 14px;
        }
      }
    }
    @media #{$until959} {
      flex: 0 0 50%;
      max-width: 50%;
      &:nth-child(n+7) {
        display: none;
      }
    }
    @media screen and (max-width: 719px) and (min-width: 600px) {
      span {
        margin: $sideSpaceSm + 2;
        width: 56px;
        height: 56px;

        &:before {
          margin-left: $colSpace;
          width: 130px;
          height: 13px;
          margin-top: -17px;
        }

        &:after {
          margin-left: $colSpace;
          width: 100px;
          height: 13px;
        }
      }
    }
    @media screen and (max-width: 599px) {
      flex: 0 0 100%;
      max-width: 100%;
      &:nth-child(n+4) {
        display: none;
      }
    }
    @media #{$until359} {
      span {
        margin: $sideSpaceSm + 2;
        width: 56px;
        height: 56px;
        border-radius: $sideSpaceSm;

        &:before {
          margin-left: $colSpace;
          width: 130px;
          height: 13px;
          margin-top: -17px;
        }

        &:after {
          margin-left: $colSpace;
          width: 100px;
          height: 13px;
        }
      }
    }
  }

}

.winners_section .preloader {
  padding: 0;
}

.winners_preloader {
  margin: 0 -#{$colSpace};
  padding: 10px 0 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  li {
    flex: 1;
    padding: 0 10px;

    span {
      flex: 1;
      max-width: 250px;
      width: 100%;
      height: 104px;
      margin: 0 10px;
      position: relative;
      display: block;
      padding-bottom: 27%;
      border-radius: 10px;
      @extend .animated-background;
    }

    @media #{$until1199} {
      span {
        margin: 10px;

      }
    }
    @media #{$until1023} {
      span {
        margin: 10px;
        height: 80px;
      }
    }
    @media #{$until767} {
      span {
        max-width: 100%;
        height: 80px;
      }
    }
  }
}

.bonus_inner_preloader {
  display: flex;
  flex: 1;
  margin-bottom: 40px;

  .bonus_inner_preloader_item {
    width: 100%;
    height: 306px;
    @extend .animated-background;
    border-radius: 8px;
    margin: 0 8px;
  }
}


.main_preloader {
  margin: 18px -24px 0;
  padding: 0;

  .header_preloader {
    margin-top: -72px;
  }

  .main_preloader_banner {
    width: 100%;
    max-width: 1900px;
    padding: 0 24px;
    margin: 16px auto 40px;
    display: block;
    height: 391px;
    border-radius: 8px;
    list-style: none;
    @include background($lightBg, --lightBg);
    @extend .animated-background;

    span {
      display: none;
    }

    @media #{$until1199} {
      height: 300px;

    }
    @media #{$until767} {
      height: 200px;

    }
  }

  .main_preloader_lines {
    @include background($lightBg, --lightBg);
    @extend .animated-background;
    width: 60%;
    height: 40px;
    border-radius: 8px;
    margin-top: 20px
  }
}

.profile_page_preloader {
  margin: 18px;

  .header_preloader {
    margin-top: -120px;
  }
}

.header_preloader {
  width: 100%;
  height: 54px;
  margin-top: -54px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media #{$until1199} {
    justify-content: flex-end;
  }

  .header_search {
    @include background($lightBg, --lightBg);
    @extend .animated-background;
    width: 255px;
    height: 32px;
    border-radius: 16px;

    @media #{$until1199} {
      display: none;
    }
  }

  .profile_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .circle_button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      @include background($lightBg, --lightBg);
      @extend .animated-background;

      @media #{$until1199} {
        display: none;
      }
    }

    .dep_btn {
      @include background($lightBg, --lightBg);
      @extend .animated-background;
      width: 97px;
      height: 30px;
      border-radius: 7px;

      @media #{$until1199} {
        display: none;
      }
    }

    .pre_header_profile {
      @include background($lightBg, --lightBg);
      @extend .animated-background;
      width: 238px;
      height: 48px;
      border-radius: 22px;

      @media #{$until1199} {
        width: 229px;
      }

      @media #{$until575} {
        width: 154px;
      }

      @media #{$until420} {
        width: 130px;
      }
    }
  }
}

@import '../../default-scss/extendsEnd';