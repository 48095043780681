%opacity300 {
    @include animStyle(opacity 0.3s);
}
%color300 {
    @include animStyle(color 0.3s);
}
%back300 {
    @include animStyle(background-color 0.3s);
}
%all300 {
    @include animStyle(all 0.3s);
}
%opacityHover07 {
    &:hover {
        opacity: 0.7;
    }
}
%opacityHover1 {
    &:hover {
        opacity: 1;
    }

}
%blackColorHover {
    &:hover {
        color: $black;
    }
}
%whiteHover07 {
    &:hover {
        color: rgba($white,0.7);
    }
}
%whiteColorHover {
    &:hover {
        color: $white;
    }
}

%textUnSelect {
	-webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
%maskedInput {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    visibility: hidden;
}

body {
    &:not(.touch_active) {
        %btnHover {
            &:hover {
                @include background($siteColor,--siteColor);
                @include color($lightColor,--lightColor);
                box-shadow: 0 0 12px rgba($hoverColor, 0.75);
                border-color: transparent;
            }
        }
        %linkHover {
            @include linkHover();
        }
        %scaleHover {
            &:hover {
                @include transStyle(scale(1.2));
            }

        }
        .main_logo a:hover {
            img,
            &:before {
                @include transStyle(scale(1.05));
            }
        }
        .dropdown_button.click_button:hover {
            box-shadow: inset $shadowLight;
        }
        %siteColorHover {
            @extend %color300;
            &:hover {
                @include color($siteColor,--siteColor);
            }
            &:active {
                @include color($pressColor,--pressColor);
            }
        }

        %siteBgHover {
            @extend %all300;
            &:hover {
                @include color($lightColor,--lightColor);
                @include background($siteColor,--siteColor);
                @include shadow(0 0 12px, $shadowColor, --shadowColor);
                border-color: transparent;
            }
            &:active {
                @include background($pressColor,--pressColor);
            }
            &:before {
                color: inherit;
            }
        }
        %siteBgHover2 {
            @extend %all300;
            &:hover {
                @include color($lightColor,--lightColor);
                @include background($siteColor,--siteColor);
                @include shadow(0 0 12px, $shadowColor, --shadowColor);
                border-color: transparent;
            }
            &:active {
                @include background($lightColor,--lightColor);
                @include color($siteColor,--siteColor);
                @include shadow(0 0 12px, $shadowColor, --shadowColor);
                border-color: transparent;
            }
        }
        %lightBgHover {
            @extend %all300;
            &:hover {
                @include background($lightBg,--lightBg);
                @include color($lightColor,--lightColor);
            }
        }

    }
    &.touch_active {

        %siteColorActive {
            @include animStyle(color 0.15s);
            &:active {
                @include color($pressColor,--pressColor);
            }
        }
        %siteBgActive {
            @include animStyle(all 0.15s);
            &:active {
                @include background($pressColor,--pressColor);
                @include color($lightColor,--lightColor);
                @include shadow(0 0 18px, $shadowColor, --shadowColor);
                border-color: transparent;
            }
            &:before {
                color: inherit;
            }
        }
        %siteBgActive2 {
            @include animStyle(all 0.15s);
            &:active {
                @include background($lightColor,--lightColor$lightColor);
                @include color($siteColor,--siteColor);
                @include shadow(0 0 12px, $shadowColor, --shadowColor);
                border-color: transparent;
            }

        }
        %lightBgActive {
            @include animStyle(all 0.15s);
            &:active {
                @include background($lightBg,--lightBg);
                @include color($lightColor,--lightColor);
            }
        }
        %btnHover {
            @include touchAfter();
        }
        %linkHover {
            @include linkTouch();
        }
        %scaleHover {
            &:active {
                @include transStyle(scale(1.2));
            }
        }
        .main_logo a:active {
            img,
            &:before {
                @include transStyle(scale(1.05));
            }
        }
        .dropdown_button.click_button:active {
            box-shadow: inset $shadowLight;
        }
    }
}
