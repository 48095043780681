@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

.sign_buttons {
    display: flex;
    button {
        @extend %button;
        white-space: nowrap;
        position: relative;
        z-index: 10;
    }
}
@media #{$until1199} {
    .header {
        .sign_buttons {
            margin-left: auto;
        }

    }
}

@import '../../default-scss/extendsEnd';