@import '../../../default-scss/variables';
@import '../../../default-scss/mixins';
@import '../../../default-scss/extendsStart';

.second_menu {
    width: 100%;
    @include overHidden();
    > ul {
      @extend %standardList;
      > li {
        &:not(:first-child):before {
          border-top: 1px solid;
          @include border($detailsBorder,--detailsBorder);
          display: block;
          content: "";
          margin: 0 $sideSpaceLg;
        }
        @include background($lightBg,--lightBg);
      }
    }
    .menu_item {
        display: flex;
        padding: 0 $sideSpaceLg;
        width: $sideBarInnerWidth;
        height: 43px;
        align-items: center;
        font-size: 140%;
        line-height: 18px;
        position: relative;
        cursor: pointer;
        @include color($textColor,--textColor);
        @include animStyle(color 0.25s linear);
        @include animDelay(0.25s);
        @include ltrAfter($textColor,--textColor,0.1);
        @extend %linkHover;
        @media screen and (min-width: 1200px) {
          color: transparent;
          @include animDelay(0s);
        }
        &:before {
          width: 32px;
          min-width: 32px;
          margin-right: $sideSpaceSm;
          text-align: center;
          @include color($textColor05,--textColor05);
          font-size: 150%;
        }
        &.icon_about:before {
          font-size: 141.7%;
        }
        &.icon_certificates:before {
          font-size: 175%;
        }
        [class*="icon_"]{
            @include color($textColor,--textColor);
            font-size: 50%;
            padding-left: $sideSpaceSm;
            margin-left: auto;
            &:before {
              display: block;
            }
        }
    }
    .drop_block {
        @include background($lightBg,--lightBg);
        margin: 0 #{-$sideSpaceLg};
    }
    .menu_sublist {
        @extend %standardList;
        width: $sideBarFullWidth;
    }
    .menu_subitem {
        font-size: 140%;
        line-height: 17px;
        position: relative;
        display: flex;
        align-items: center;
        min-height: 40px;
        padding: $colSpaceSm 2*$sideSpaceMd $colSpaceSm 4*$sideSpaceMd;
        @include color($textColor05,--textColor05);
       
        @include animDelay(0.25s);
        @include ltrAfter($textColor,--textColor,0.1);
        @extend %linkHover;
        
        @media screen and (min-width: 1200px) {
          color: transparent;
          @include animDelay(0s);
        }
    }
    @media #{$until1199} {
      .menu_item {
        width: 100%;
        height: 52px;
        font-size: 140%;
        line-height: 18px;
        &:before {
          width: 24px;
          min-width: 24px;
          margin-right: $sideSpaceMd;
        }
        &.icon_about:before {
          font-size: 171.4%;
        }
        &.icon_certificates:before {
          font-size: 200%;
        }
      }
      .menu_sublist {
        width: 100%;
      }
      .menu_subitem {
        font-size: 140%;
        line-height: 18px;
        padding: $sideSpaceMd 0 $sideSpaceMd 3*$sideSpaceLg ;
      }
    }
}
.touch_active .second_menu .menu_item:after {
  display: none;
}

@import '../../../default-scss/extendsEnd';