@import '../../../default-scss/variables';
@import '../../../default-scss/mixins';

.page_loader {
   width: 100%;
   height: 100%;
   @include background($bodyBg,--bodyBg);
   position: fixed;
   top: 0;
   left: 0;
   z-index: 999;
   display: flex;
   justify-content: center;
   align-items: center;
   background-size: cover;
   background-position: center;
   &.notfixed{
    position: absolute;
  }
  .logo_block {
    position: relative;
    width: 56px;
    height: 56px;
    img {
      position: relative;
      z-index: 2;
      width: 100%;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      animation-name: roundScale;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
 }

@keyframes roundScale {
  0% {
    @include transStyle(scale(1));
  }
  50% {
    @include transStyle(scale(1.2));
  }
  100% {
    @include transStyle(scale(1));
  }
}
