@import 'default-scss/variables';
@import 'default-scss/fonts';
@import 'default-scss/mixins';
@import 'default-scss/colors';
@import 'default-scss/extendsStart';

[dir="rtl"] {
    .header {
        .search_block {
            margin-left: $sideSpaceMx;
            margin-right: 0;

            .results_list ul li {
                button {
                    margin-left: 0;
                    margin-right: $sideSpace;
                }

                img {
                    margin-left: 10px;
                    margin-right: 0;
                }
            }
        }

        .lg_block {
            margin-left: auto;
            margin-right: 0;

            img {
                margin-right: 0;
                margin-left: $colSpaceSm;
            }
        }

        .lg_btn:before {
            padding-left: 0;
            padding-right: $colSpaceSm;
        }

        .user_block {
            margin-left: 0;
            margin-right: $sideSpace;

            .deposit_btn {
                margin-right: $colSpace;
                margin-left: 0;
            }
        }

        .notifications {
            margin-right: $sideSpaceSm;
            margin-left: 0;
        }

        .user_image {
            margin-left: $colSpace;
            margin-right: 0;
        }


        .tournament_btn {
            margin-left: 10px;
            margin-right: 0;
        }

        .login_button {
            margin-right: 12px;
            margin-left: 0;
        }

        .register_button {
            margin-left: 0;
            margin-right: 24px;
        }

        .nots_content {
            ul li {
                img {
                    margin-left: $sideSpaceSm;
                    margin-right: 0;
                }

                .not_info {
                    margin-left: $colSpaceSm;
                    margin-right: 0;
                }
            }

            .nots_description {
                margin-right: 0;
                margin-left: $sideSpace;
            }
        }
    }

    .tron_btn {
        justify-content: center;
    }

    .tronlink_icon {
        margin-right: 0;
        width: auto;
    }

    .field_block .MuiInputBase-root input {
        padding-right: 0;
        padding-left: 48px !important;
    }

    .sign_popup {
        .field_block .MuiFormControlLabel-root{
            margin-right:0;
            margin-left: auto;
            width: 100%;
        }
        .field_block .select__value-container {
            text-align: right;
        }

        .modal_header .close_btn {
            right: unset;
            left: 12px;
        }

        .field_block {
            .field_name {
                text-align: right;
            }

            input {
                padding: 6px 40px 6px 12px;
            }
        }

    }

    .root_inner > .help_btn {
        left: 36px;
        right: unset;

        &:before {
            margin-right: 0;
            margin-left: 8px;
        }
    }

    .field_block .MuiInputAdornment-root {
        left: 5px;
        right: unset;
    }

    #pwa_block {
        .pwa_buttons {
            padding-left: 0;
            padding-right: 40px;
        }

        .deny_btn {
            margin-left: 0;
            margin-right: 56px;
        }

        .image_block {
            margin-right: 0;
            margin-left: 22px;
        }
    }


    .promotion_inner .close_btn,
    .close_btn {
        left: 12px;
        right: unset;
    }

    .container .block .block_inner .image_block {
        left: -56px;
        right: unset;
    }

    .sidebar_btn {
        right: auto;
        left: 0;

        &.sidebar_btn:before {
            @include transStyle(scaleX(-1));
        }

        &:hover:before {
            animation: leftAnimate;
            animation-duration: 0.5s;
        }
    }


    .sidebar_menu {
        left: auto;
        right: 0;
        padding-right: 0;
        padding-left: 21px;

        .main_logo img {
            @include animStyle(all 0.5s linear);
            @include torigin(right center);
        }

        + .root_inner {
            padding-right: $sideBarWidth;
            padding-left: 0;
            @include animStyle(padding $sidebarTrs);


            .header {
                padding-right: $sideBarWidth;
                padding-left: 0;
                @include animStyle(padding $sidebarTrs);

            }
        }


        .main_logo img {
            margin-right: -95px;
        }

        .user_block .deposit_btn {
            left: 14px;
            right: auto !important;
        }

        &.sidebar_toggle {
            .main_logo {
                margin-left: 0;
                margin-right: $sideSpaceMx;

                img {
                    margin-right: 7px;
                }
            }

            @media screen and (min-width: 1440px) {
                + .root_inner {
                    padding-right: $sideBarFullWidth;
                    padding-left: 0;

                    .header {
                        padding-right: $sideBarFullWidth;
                        padding-left: 0;
                    }

                    .game_innerpage {
                        right: $sideBarFullWidth;
                        left: 0;
                    }

                    .promotion_inner {
                        margin-right: -$sideBarFullWidth;
                        margin-left: 0;
                    }
                }
            }
        }


    }

    .providers_list .all_btn {
        margin-left: 0;
        margin-right: $colSpaceSm;

        &:before {
            margin-left: 0;
            margin-right: $colSpace;
        }
    }

    .second_menu .menu_item [class*="icon_"] {
        padding-right: $sideSpaceSm;
        padding-left: 0;
        margin-right: auto;
        margin-left: 0;
    }

    .profile_header .inner_block {
        margin-right: 0;
        margin-left: 30px;
    }

    .profile_block {
        margin-left: 0;
        margin-right: 12px;
    }

    .profile_page {
        .input_block input,
        .details_list .detail_result input {
            padding: 4px 5px 5px 35px;
        }

        .details_list .profile_lng {
            margin-right: 0;
            margin-left: 5px;
        }

        .logout_btn:before {
            margin-right: 0;
            margin-left: 8px;
        }

        .docs_list {

            .doc_name {
                padding-right: 0;
                padding-left: 24px;
                margin-left: 0;
                margin-right: 15px;

                a:before {
                    margin-left: 16px;
                    margin-right: 0;
                }
            }

            .doc_description {
                padding-right: 0;
                padding-left: 24px;
            }
        }

        .input_block input {
            margin-left: 0;
            margin-right: auto;
        }

        .input_block,
        .details_list .detail_result {
            margin-right: 0;
            margin-left: 30px;
        }

        .profile_menu a:before {
            margin-right: 0;
            margin-left: 18px;

        }

        .edit_button {
            margin-right: auto;
            margin-left: 0;

            &:before {
                margin-right: 0;
                margin-left: 8px;
            }
        }

        .dropdown_button .icon_down {
            margin-right: 45px;
            margin-left: 0;
        }

        .statuses-with-icon-block {
            margin-right: 8px;
            margin-left: 0;
        }

        .pass_change_info {
            right: 280px;
            left: 140px;
        }

        .pass_change .dropdown_button button span:before {
            margin-right: 0;
            margin-left: 6px;
        }

        .verification {
            padding-left: 0;
            padding-right: 32px;

            .status_info:before {
                margin-left: 0;
                margin-right: 5px;
            }
        }

        .profile_header {
            .deposit_btn:before {
                margin-right: 0;
                margin-left: 9px;
            }

            .image_block {
                margin-right: 0;
                margin-left: 27px;
            }
        }
    }

    .bonuses_list > li {
        .bonus_info {
            padding-right: 0;
            padding-left: 40px;
            margin-left: auto;
            margin-right: 0;
        }

        .bonus_image {
            margin-right: 40px;
        }
    }

    .profile_block > .deposit_btn:before {
        margin-right: 0;
        margin-left: 6px;
    }

    .withdraw_balance, .total_balance, .bonus_balance {
        margin-right: auto;
        margin-left: 0;
    }

    .time-out_details .time-out_content .field_block .time_out_year_picker_range .react-datepicker-range-wrapper {
        padding-right: 50px;
        padding-left: 0;
    }

    .profile_page .details_list .detail_result .react-datepicker-wrapper:before {
        left: 15px;
        right: auto;
    }

    .input_block .success {
        left: 25px;
        right: auto;
    }

    .deposit-limits_details .deposit-limits_content_create_block_item .field_name,
    .available-time_details .available-time_content_create_block_item .field_name {
        margin-left: 0;
        margin-right: 10px;
    }

    .profile_page .details_list .standard_select .select__indicator,
    .field_block .MuiCheckbox-root,
    .deposit-limits_details .deposit-limits_existing_block_alert .alert_icon,
    .self-exclusion_details .self-exclusion_existing_block_alert .alert_icon,
    .available-time_details .available-time_existing_block_alert .alert_icon,
    .content_alert .alert_icon {
        margin-left: 10px;
        margin-right: 0;
    }

    .field_block .select__single-value {
        padding-left: 20px;
        padding-right: 0;
    }

    .field_block .select__indicator {
        margin-left: 18px;
        margin-right: 0;
    }

    .promotion_inner .promo_block .promo_info {
        margin-right: 0;
        margin-left: 40px;
    }

    .promotion_inner .promo_block img {
        margin-right: 0;
        margin-left: 20px;
    }

    .history_filter {
        .select__single-value {
            padding-right: 0;
            padding-left: 20px;
        }

        .select__indicator {
            margin-left: 18px;
            margin-right: 0;
        }

        .filter_buttons button:not(:first-child) {
            margin-right: 15px;
            margin-left: 0;
        }
    }

    .deposit-limits_details .deposit-limits_content_create_block_item .field_block .MuiTextField-root input {
        padding: 5px 25px 5px 15px;
    }

    .deposit-limits_details .deposit-limits_content_create_block_item .field_block .currency_icon {
        right: 10px;
        left: unset;
    }

    .date_picker_range_container .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker-range .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input .time_select .time_select_item .select__value-container {
        padding-left: 0;
        padding-right: 0;
    }

    .date_picker_range_container .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker-range .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input .time_select .time_select_item .select__control .select__indicator {
        margin-right: 0;
        margin-left: 10px;
    }

    .date_picker_range_container .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker-range .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input .time_select .time_select_item .select__value-container .select__single-value {
        padding-left: 0;
        padding-right: 13px;
        text-align: right;
        width: 30px;
        margin-right: auto;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        padding-right: 5px;
    }

    .date_picker_range_container .react-datepicker-wrapper:after {
        right: 18px;
        left: 0;
    }

    @media #{$until1199} {
        .header {
            .tournament_btn {
                margin-right: 60px;
                margin-left: 40px;
            }

            .sign_buttons {
                margin-left: 0;
                margin-right: auto;
            }

            .user_block {
                margin-left: 0;
                margin-right: $sideSpaceSm;
                padding-left: 8px;
            }

            .profile_block {
                margin-left: -6px;
                margin-right: auto;
            }

        }
        .lg_block {
            margin-left: auto;
            margin-right: 0;

            img {
                margin-right: 0;
                margin-left: $colSpaceSm;
            }
        }

        .second_menu .menu_subitem {
            padding-right: 32px;
        }

        .lg_btn:before {
            margin-left: 0;
            padding-left: 0;
            padding-right: $colSpaceSm;
        }

        .sidebar_menu {
            width: 0;
            padding: 0;
            overflow: visible;

            + .root_inner {
                padding-right: 0;
                @include animStyle(margin $sidebarTrs);
                width: 100vw;

                .header {
                    padding-right: 0;
                    @include animStyle(margin $sidebarTrs);
                    width: 100vw;
                    right: 0;
                    left: 0;
                }

                .bottom_menu .menu_list {
                    right: 0;
                    left: unset;
                }
            }

            .sidebar_btn {
                padding-left: $sideSpaceSm;
            }

            &.sidebar_toggle {
                + .root_inner {
                    padding-right: 0;
                    margin-right: $sideBarMobile;
                    padding-left: 0;
                    margin-left: 0;

                    .header {
                        padding-left: 0;
                        margin-left: 0;
                        padding-right: 0;
                        margin-right: $sideBarMobile;
                        right: 0;

                        .sign_buttons:after,
                        .register_button {
                            margin-right: $sideBarMobile;
                            margin-left: 0;
                        }
                    }

                    .games_page .filter_buttons,
                    > .help_btn {
                        margin-right: -$sideBarMobile;
                        margin-left: 0;
                    }

                    .games_page .navigation_block,
                    .bottom_menu .menu_list {
                        margin-right: $sideBarMobile;
                        margin-left: 0;
                    }

                    .game_innerpage {
                        right: $sideBarMobile;
                        left: 0;
                    }

                    .promotion_inner {
                        margin-right: -$sideBarMobile;
                        margin-left: 0;
                    }
                }

                .sidebar_btn {
                    &:after {
                        left: unset;
                        right: 0;
                    }
                }
            }
        }

        .sidebar_btn {
            padding: 4+$sideSpace $sideSpace 4+$sideSpace 0;
            right: 100%;
            left: 0;
        }
    }

    @media #{$until991} {

        .providers_list .all_btn:before {
            margin-right: 0;
        }
        .profile_page .profile_menu a:before {
            margin-left: 0;
        }
        .profile_header .verification {
            right: auto;
            left: 20px;
        }
    }

    @media #{$until767} {
        .input_block {
            margin-right: 0;
        }
        .dropdown_inner .pass_change_info {
            margin: 0 20px;
        }
    }

    @media #{$until575} {

        .input_block .success {
            left: 25px;
            right: auto;
        }
        .profile_page {
            .dropdown_button .icon_down {
                margin-right: 0;
            }

            .docs_list .doc_status {
                padding: 0;
            }
        }
        .bonuses_list {
            li .bonus_image {
                position: relative;
                bottom: unset;
                right: unset;
            }

            .bonus_details {
                .detail_type {
                    text-align: right;
                    padding-left: 15px;
                    padding-right: 0;
                }

                .detail_info {
                    text-align: left;
                    padding-right: 15px;
                    padding-left: 0;
                }
            }
        }
        .date_picker_range_container {
            .react-datepicker-range-wrapper {
                text-align: right;
            }
        }
        .date_picker_range_container .react-datepicker-wrapper:after {
            right: 0;
            left: 18px;
        }
    }

    @media #{$until479} {
        .profile_page .docs_list .doc_name {
            margin-right: 0;

            &:before {
                width: 37px;
            }
        }
    }
}

@import 'default-scss/extendsEnd';
