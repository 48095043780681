@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

.search_block {
    .results_list {
        position: absolute;
        height: 0;
        overflow: hidden;
        opacity: 0;
        z-index: 10;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: 5px;
        padding-top: 1px;
        @include background($lightBg,--lightBg);
        box-shadow: 3px 2px 10px rgba($black,0.08);
        border-radius: 0 0 $sideSpaceSm $sideSpaceSm;
        @extend %opacity300;
        @extend %customScroll;
        &:before {
            position: absolute;
            top: -$sideSpaceSm;
            left: $sideSpaceLg;
            content: "";
            border-width: 0 $sideSpaceSm $sideSpaceSm;
            @include border($lightBg,--lightBg);
            border-left-color: transparent;
            border-right-color: transparent;
            border-style: solid;
        }

        .more_link {
            padding: 10px 15px;
            text-align: center;
            button {
                display: inline-block;
                vertical-align: top;
                @include color($siteColor,--siteColor);
                font-size: 120%;
                line-height: 15px;
                border: none;
                background: transparent;
                padding: 0;
                position: relative;
                @include linkAfter(1px);
                @extend %linkHover;
                &:after {
                    @include color($siteColorHover,--siteColorHover);
                    z-index: 2;
                }

                &:hover {
                    @include color($siteColorHover,--siteColorHover);
                    &:before {
                    display: none;
                    }
                }
            }
        }
        .scrollbar-container {
            max-height: 400px;
        }
        ul {
            @extend %standardList;
            @include background($lightBg,--lightBg);
            width: 100%;
            li {
                display: flex;
                align-items: center;
                padding: 10px;

                &:not(:first-child) {
                    border-top: 1px solid;
                    @include border($detailsBorder,--detailsBorder);
                }
                img {
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    object-fit: cover;
                    border-radius: 7px;
                    @include overHidden();
                    margin-right: 10px;
                }
                .menu_item {
                    flex: 1;
                    width: 40%;
                    font-size: 120%;
                    line-height: 17px;
                    font-family: $bold;
                    margin-right: auto;
                }
                button {
                    @extend %button;
                    @extend %smallButton;
                    @extend %mainButton;
                    min-width: 60px;
                    margin-left: $sideSpace;
                    align-self: center;
                    @media #{$until1439} {
                        margin-left: $sideSpaceSm;
                    }
                }

            }
        }
    }
    &.showed .results_list {
        min-height: 50vh;
        opacity: 1;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 2px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: $siteColor;
            border-radius: 10px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #b30000;
          }
    }
}

@import '../../default-scss/extendsEnd';
