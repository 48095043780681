@import '../../../default-scss/variables';
@import '../../../default-scss/mixins';
@import '../../../default-scss/extendsStart';

.sidebar_footer {
    text-align: center;
    .copyrights {
        @include background($timeBg,--timeBg);
        width: $sideBarInnerWidth;
        padding: $colSpace $sideSpaceLg;
        line-height: 14px;
        @include color($textColor05,--textColor05);
        @include animStyle(color 0.25s linear);
        @include animDelay(0.25s);
        @media screen and (min-width: 1200px) {
            color: transparent;
            @include animDelay(0s);
        }
    }
    @media #{$until1199} {
        display: flex;
        justify-content: space-between;
        padding: 2*$sideSpaceMd $sideSpaceLg;
        align-items: flex-end;

        .help_btn {
            all: unset;
            font-size: 300%;
        }
    }
    .lg_block {
        margin-bottom: 2px;
        display: flex;
        align-items: flex-end;
    }
    .lg_list {
        display: flex;
        flex-direction: column-reverse;
        box-shadow: $shadowLight;
    }
    .partner_logo img {
        width: auto;
        height: 36px;
    }
}


@import '../../../default-scss/extendsEnd';
