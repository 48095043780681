@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

.container_verification_page {
  height: 50vw;
  @extend %centerBlock;
    .home_btn {
      @extend %button;
      @extend %mainButton;
      @extend %largeButton;
      width: 244px;
      margin-top: 2*$sideSpaceMd;
    }
}

@import '../../default-scss/extendsEnd';
