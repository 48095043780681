@import '../../default-scss/variables';
@import '../../default-scss/mixins';
@import '../../default-scss/extendsStart';

@keyframes pwaAnimate{
  0%{
    @include transStyle(translateY(150%));
  }
  90% {
    @include transStyle(translateY(-90%));
  }
  100%{
    @include transStyle(translateY(-100%));
  }
}

#pwa_block {
  display: none;
  position: fixed;
  left: 50%;
  margin-left: -370px;
  width: 730px;
  margin-top: -40px;
  z-index: 99;
  //bottom: -300px;
  top: 100%;
  animation-delay: 2.25s;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  animation-name: pwaAnimate;
  animation-timing-function: linear;
  margin-bottom: 40px;
  border-radius: 10px;
  @include transStyle(translate3d($sideBarWidth,300px,0));
  @include background($lightBg,--lightBg);
  @include animStyle(all $sidebarTrs);
  .block_inner {
    @include background($lightBg,--lightBg);
    width: 100%;
    border-radius: $sideSpaceMd - 2;
    box-shadow: $shadowLight;
    display: flex;
    align-items: center;
    padding: 14px;
  }
  .image_block {
    width: 60px;
    height: 60px;
    padding: 7px;
    border-radius: 15px;
    @include background($black0F,--black0F);
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 22px;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .info_block {
    flex: 1;
    width: 50%;
    display: flex;
    align-items: center;
  }
  .info_inner {
    flex: 1;
    width: 50%;
    padding-right: $sideSpaceMd;
  }
  .pwa_title {
    font-size: 160%;
    line-height: 22px;
    font-family: $bold;
    @include color($lightColor,--lightColor);
  }
  .pwa_text {
    font-size: 140%;
    line-height: 17px;
    margin-top: 5px;
    @include color($lightColor,--lightColor);
  }
  .pwa_buttons {
    display: flex;
    align-items: center;
    padding-left: 40px;
  }
  .deny_btn {
    border: none;
    background: transparent;
    padding: 0;
    font-size: 140%;
    @include color($lightColor,--lightColor);
    @include siteColorHover;
    margin-left: 56px;
    &:before {
      display: block;
    }
  }
  .accept_btn {
    @extend %button;
    @extend %mainButton;
    @extend %middleButton;
    height: 36px !important;
  }
  @media #{$until1199} {
    @include transStyle(translate3d(0,0,0));
    margin-top: -80px;    
  }
  @media #{$until767} {
    width: 100%;
    left: 0;
    margin-left: 0;
    .block_inner {
      padding: 10px 10px 10px 40px;
      border-radius: 0;
      position: relative;
    }
    .pwa_title {
      font-size: 140%;
      line-height: 17px;
    }
    .pwa_text {
      font-size: 120%;
      line-height: 15px;
    }
    .deny_btn {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -6px;
      margin-left: 0;
      font-size: 120%;
    }
    .accept_btn {
      font-size: 140%;
      height: 30px;
      padding: 0 14px;
      border-radius: 5px;
    }
    .image_block {
      width: 48px;
      height: 48px;
      min-width: 48px;
      padding: 6px;
      margin-right: 15px;
      border-radius: 10px;
    }
    .pwa_buttons {
      padding-left: 20px;
    }
  }
  @media #{$until575} {
    .pwa_text {
      display: none;
    }
    .block_inner {
      padding-left: 30px;
    }
    .pwa_title {
      font-size: 120%;
      line-height: 15px;
    }
    .deny_btn {
      font-size: 100%;
      margin-top: 5px;
    }
    .accept_btn {
      font-size: 120%;
      height: 24px;
    }
    .image_block {
      width: 36px;
      height: 36px;
      min-width: 36px;
      padding: 4px;
      margin-right: 10px;
      border-radius: 8px;
    }
  }
  @media #{$until413} {
    .pwa_text {
      display: none;
    }
    .block_inner {
      padding-left: 28px;
    }
    .pwa_title {
      font-size: 100%;
      line-height: 13px;
    }
    .deny_btn {
      font-size: 80%;
      padding: 6px;
      left: 4px;
      margin-top: -10px;
    }
    .accept_btn {
      font-size: 100%;
      height: 20px;
    }
    .image_block {
      width: 26px;
      height: 26px;
      min-width: 26px;
      padding: 3px;
      border-radius: 6px;
    }
  }
}

@import '../../default-scss/extendsEnd';